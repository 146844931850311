<template>
  <el-row class="mx-4 border">
    <el-col>
      <el-table
        :data="process"
        empty-text="Não há processos atribuídos a esse pacote"
        style="width: 100%"
      >
        <el-table-column
          label="Id Usuário"
          prop="user_id"
          width="100"
        />
        <el-table-column
          label="Nome"
          prop="user_name"
        />
        <el-table-column
          label="Turma"
          prop="group_name"
        />
        <el-table-column
          label="Capa"
          prop="cover_path"
        >
          <template slot-scope="{row}">
            <a
              v-if="process.status == 'files_processed' "             
              target="_blank"
              :href="row.cover_path"
            >Baixar</a>
          </template>
        </el-table-column>
        <el-table-column
          label="Caderno"
          prop="book_path"
        >
          <template slot-scope="{row}">
            <a
              v-if="process.status == 'files_processed' "             
              target="_blank"
              :href="row.book_path"
            >Baixar</a>
          </template>
        </el-table-column>
        <el-table-column
          label="Cartão"
          prop="card_path"
        >
          <template slot-scope="{row}">
            <a
              v-if="process.status == 'files_processed' "             
              target="_blank"
              :href="row.card_path"
            >Baixar</a>
          </template>
        </el-table-column>
        <el-table-column
          label="Status do processo"
          prop="status"
          width="150"
        />
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
export default {
    props:{
        process:{
            type: Array,
            required: true
        }
    }
}</script>