import moment from "moment";

export default {
    state: {
        list: [],
        is_loading_logins_per_week: false
    },
    actions: {
        getLoginsPerWeek({commit, state}, params = '') {
            state.is_loading_logins_per_week = true
            return axios.get(`/admin/report/logins-per-week?${params}`)
            .then(response => {1

              commit('SET_LOGINS_PER_WEEK', response.data.data.map(c => ({
                ...c,
                week_begin_formatted : moment(c.week_begin).format('DD/MM/YYYY hh:mm'),
                week_end_formatted: moment(c.week_end).format('DD/MM/YYYY hh:mm'),
                cycle_begin_formatted : moment(c.cycle_begin).format('DD/MM/YYYY hh:mm'),
                cycle_end_formatted: moment(c.cycle_end).format('DD/MM/YYYY hh:mm'),
              })))

              return response

            })
            .finally(()=> state.is_loading_logins_per_week = false);
        },

    },
    mutations: {
        SET_LOGINS_PER_WEEK(state, payload) {
            state.list = payload;
        },
    },
    getters: {
        logins_per_week_list: state => state.list,
        is_loading_logins_per_week: state => state.is_loading_logins_per_week,
    }
};
