<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />
        <el-dialog
          title="Editar questão"
          :visible.sync="editing"
          fullscreen
          center
          @closed="onCancel"
        >
          <RegisterQuestion
            v-if="question && editing"
            @saved="getQuestions(); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>
        <el-dialog
          title="Cadastrar questão"
          :visible.sync="creating"
          center
          fullscreen
          @closed="onCancel"
        >
          <RegisterQuestion
            v-if="question && creating"
            @saved="getQuestions(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>
        <el-dialog
          :title="`Informação da Questão ${question ? question.description: ''}`"
          width="50%"
          class="with-border"
          :visible.sync="showing"
          center
          @closed="onCancel"
        >
          <ShowQuestion
            v-if="question"
            :question="question"
          />
        </el-dialog>

        <FilterList v-model="search" />

        <question-list @changed="getQuestions(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-question')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                size="mini"
                type="info"
                icon="el-icon-view"
                @click="onShow(row)"
              >
                Visualizar
              </el-button>
              <el-button-group />
            </el-button-group>
          </template>
        </question-list>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import QuestionList from "@/admin/Pages/question/components/QuestionList"
import RegisterQuestion from "@/admin/Pages/question/components/RegisterQuestion.vue"
import ShowQuestion from "@/admin/Pages/question/components/ShowQuestion.vue"
import FilterList from "@/admin/Pages/question/components/FilterList.vue"
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        QuestionList,
        RegisterQuestion,
        FilterList,
        ShowQuestion
    },
    data() {
      return {
        editing: false,
        showing: false,
        creating: false,
        fullscreen: false,
        search:'',
        meta : {
            current_page: 1,
            per_page: 10,
            total: 10
        },
      }
    },
    computed:{
      ...mapGetters(['questions','question','permissions']),
      filters(){
        return `${this.search}&page=${this.meta.current_page}&per_page=${this.meta.per_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getQuestions(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      }
    },
    methods:{
        ...mapActions(['getQuestions']),
        ...mapMutations(['SET_QUESTION']),
        onEdit(question){
            this.SET_QUESTION(question);
            this.editing = true;
        },
        onCreate(){
            this.SET_QUESTION({
                description: '',
                content: '',
                exam_name: '',
                choices: []
            });
            this.creating = true;
        },
        onShow(question){
            this.showing = true;
            this.SET_QUESTION(question);
        },
        onCancel(){
            this.editing = false;
            this.creating = false;
            this.SET_QUESTION(null);
        },
    },
}
</script>
