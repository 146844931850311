<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Disciplina"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="50"
            placeholder="Disciplina "
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Sigla"
          class="w-100"
          prop="acronym"
          :error="errors.acronym"
        >
          <el-input
            v-model="form.acronym"
            clearable
            name="acronym"
            show-word-limit
            maxlength="10"
            placeholder="Sigla"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-row class="mt-5 mb-2">
        <el-col>
          <el-button
            type="primary"
            @click="onSubmit"
          >
            Salvar
          </el-button>
          <el-button
            type="warning"
            @click="$emit('cancel')"
          >
            Cancelar
          </el-button>
        </el-col>
      </el-row>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";
import editor from "@/admin/utils/Editor";

export default {
  mixins:[ RequestErrors ],
  props: {
    pageId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      editor,
      rules,
    }
  },
  computed: {
    ...mapGetters(['subject']),
    form: {
      get() {
        return this.$store.state.subject.current;
      },
      set(page) {
        this.SET_SUBJECT(page);
      }
    }
  },
  methods: {
    ...mapMutations(['SET_SUBJECT']),
    ...mapActions(['updateSubject']),
    onSubmit() {
    this.$refs['form'].validate((valid) => {
      if (valid) {
          this.$loading();
        const action = this.subject.id ? this.updateSubject({ data: this.getData(), id: this.subject.id }) : {};
          action
            .then(() => {
              this.$message({
                message: 'Página salva com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
    getData() {
      let formData = new FormData();
      formData.append('name', this.form.name)
      formData.append('acronym', this.form.acronym)
       if(this.subject.id)
        formData.append('_method','put');
      return formData;
    }
  },
}
</script>
