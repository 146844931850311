<template>
  <el-row class="mx-5">
    <el-col>
      <el-card>
        <PageHeader 
          slot="header" 
        >
          <template #right>
            <el-button
              type="primary"
              :loading="is_exporting"
              @click="exportData()"
            >
              Baixar relatório <i class="fa fa-download" />
            </el-button>
          </template>  
        </PageHeader>
        <el-skeleton
          :rows="10"
          :loading="is_loading"
          animated
          class="mx--4"
        >
          <el-table
            :data="trails"
            style="width: 100%"
          >
            <el-table-column
              label="Nome"
              prop="trail_name"
            />
            <el-table-column
              label="Alunos nesta trilha"
              prop="count_students"
            />
            <el-table-column
              label="Porcentagem"
              prop="percentage"
            />
          </el-table>
        </el-skeleton>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import downloadFile from "@/utils/downloadFile.js"

export default{
    data() {
        return {
          is_loading: true,
          is_exporting: false,
          trails: [],
        }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
              this.is_loading= true;
              this.getReport();
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
      getReport(){
        axios.get(`/admin/report/students-per-trail`)
        .then(({data: { data: trails }}) => {
          this.trails = trails;
          this.is_loading = false;
        })
      },
      exportData(){
        if(this.is_exporting){
          this.$message({ message: 'Em download, aguarde.', type: 'warning', showClose: true})
        } else {
          this.is_exporting = true;
          downloadFile("/admin/report/students-per-trail/export", this.$moment() + "-relatorio-alunos-por-trilha.xlsx")
          .finally(() => this.is_exporting = false)
        }
      }
    },
}
</script>
