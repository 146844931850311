
export default {
    state: {
        list: [],
        current: null,
        isLoadingSchools: false,
    },
    actions: {
        getSchools({commit, state}, params = '') {
          state.isLoadingSchools = true;
            return axios.get(`/admin/school?${params}`)
            .then(r => {
                commit("SET_SCHOOLS", r.data.data.map(s=> ({
                    ...s,
                    search : `id:${s.id}-nome:${s.name}-schoolid:${s.schoolid}`
                })));
                return r
            })
            .finally(() => state.isLoadingSchools = false);
        },
        getSchool({commit}, params) {
            return axios.get(`/admin/school/${params.data.id}`)
            .then(r => {
                commit("SET_SCHOOL", r.data.data);
                return r
            });
        },
    },
    mutations: {
        SET_SCHOOL(state, payload) {
            state.current = payload;
        },
        SET_SCHOOLS(state, payload) {
            state.list = payload;
        }
    },
    getters:{
      schools: state => state.list.filter(s=>s.platformId == 4),
      all_schools: state => state.list,
      isLoadingSchools: state => state.isLoadingSchools,
    }
};
