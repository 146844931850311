export default {
  name: [
    { required: true, message: 'O nome é obrigatório.', trigger: 'blur' },
    { min: 5, max: 50, message: 'O nome deve ter entre 5 e 50 caracteres.', trigger: 'blur' }
  ],
  acronym: [
    { required: true, message: 'A sigla é obrigatória.', trigger: 'blur' },
    { min: 3, max: 10, message: 'O nome deve ter entre 3 e 10 caracteres.', trigger: 'blur' }
  ],
}
