<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />
        <FilterList v-model="search" />   
        <el-dialog
          :title="quiz && quiz.id ? 'Editar Quiz' : 'Novo Quiz'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getQuizzes(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>
        <List @changed="getQuizzes(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-quiz')"
                size="mini"
                type="warning"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                v-if="permissions.includes('delete-quiz')"
                size="mini"
                icon="el-icon-delete"
                type="danger"
                @click="onDelete(row)"
              >
                Apagar
              </el-button>
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="meta.per_page"
            :page-count="meta.last_page"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/quiz/components/List"
import Register from "@/admin/Pages/quiz/components/Register"
import FilterList from "@/admin/Pages/quiz/components/FilterList"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
        FilterList,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          used: null,
          meta : {
              current_page: 1,
              total: 10,
              per_page: 15,
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','quiz','quizzes']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
                this.handleChange()
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getQuizzes', 'deleteQuiz', 'getModules']),
        ...mapMutations(['SET_QUIZ','SET_HISTORY']),
        handleChange(){
          this.getQuizzes(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                })
                .finally(()=>this.$loading().close())
        },
        onEdit(quiz){
          this.registering = true;
          this.SET_QUIZ(quiz)
        },
        onCreate(){
            this.SET_QUIZ({
              name: '',
              description: '',
              file: null
            });
            this.registering = true;
        },
        onShow(quiz){
          this.SET_QUIZ(quiz)
          this.showing = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_QUIZ(null);
        },
        onDelete(quiz){
          this.$confirm('Tem certeza que deseja apagar o quiz? Qualquer conteúdo de módulo relacionado a esse quiz será apagado.', 'Atenção', {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning'
          })
          .then(() => {
            this.deleteQuiz(quiz)
            this.$message({
              message: 'Quiz apagado com sucesso',
              type: 'success'
            })
          })
          .then(()=>this.getQuizzes(this.filters))
          .catch(() => {
            this.$message({
                type: 'info',
                message: 'Ação cancelada!'
            });
          });
        },
    },
}
</script>
