export default {
    state: {
        list: [],
        current: null,
        isLoadingPermissions: false,
    },
    actions: {
        getPermissions({commit, state}, params = '') {
            state.isLoadingPermissions = true;
            return axios.get(`/admin/permission?${params}`)
            .then(r => {
                commit("SET_PERMISSION_LIST", r.data.data.map(p=>{
                    return {type: p.name.split("-")[1],...p}
                }));
                return r;
            })
            .finally(() => state.isLoadingPermissions = false);
        },
        getPermission({commit}, params) {
            return axios.get(`/admin/permission/${params.data.id}`,).then(r => {
                commit("SET_PERMISSION", r.data.data);
            });
        },
        createPermission({commit}, params) {
            return axios.post(`/admin/permission/`, params.data);
        },
        updatePermission({commit}, params) {
            return axios.put(`/admin/permission/${params.id}`, params.data);
        },
        deletePermission({commit}, params) {
            return axios.delete(`/admin/permission/${params.id}`);
        }
    },
    mutations: {
        SET_PERMISSION_LIST(state, payload) {
            state.list = payload;
        },
        SET_PERMISSION(state, payload) {
            state.current = payload;
        }
    },
    getters: {
        permission_list: state => state.list,
        permission: state => state.current,
        isLoadingPermissions: state => state.isLoadingPermissions
    }

};
