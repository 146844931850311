<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingModules && modules.length == 0"
      animated
    >
      <el-table
        v-if="!isLoadingModules"
        :data="modules"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="p-4">
              <Details :module="props.row" />
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="$loading(); getModuleLogs(props.row).then(()=>$loading().close())"
                >
                  Histórico de mudanças
                </el-button>
                <el-button
                  v-if="permissions.includes('delete-module')"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  :disabled="props.row.count_selections > 0"
                  @click="onDelete(props.row)"
                >
                  Apagar
                </el-button>
                <el-button
                  v-if="!props.row.published && permissions.includes('update-module')"
                  size="mini"
                  type="success"
                  :disabled="!props.row.can_be_enabled"
                  @click="onEnable(props.row)"
                >
                  Habilitar
                </el-button>
                <el-button
                  v-if="props.row.published && permissions.includes('update-module')"
                  size="mini"
                  type="warning"
                  :disabled="!props.row.can_be_disabled"
                  @click="onDisable(props.row)"
                >
                  Desabilitar
                </el-button>
                <a
                  v-if="props.row.exam_file"
                  class="el-button el-button--primary el-button--mini"
                  size="mini"
                  type="danger"
                  :href="props.row.exam_file.url"
                  target="_blank"
                  download
                >
                  Baixar prova
                </a>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Id"
          prop="id"
          width="50"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          label="Disciplina"
          prop="subject.name"
        />
        <el-table-column
          label="Conteúdos"
          prop="count_contents"
          width="100"
        />
        <el-table-column
          label="Subtópico"
          prop="subtopic.name"
        />
        <el-table-column
          label="Status"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.published ? 'Habilitado' : 'desabilitado' }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="Liberação"
          width="400"
        >
          <template slot-scope="props">
            <ModuleReleasedAt :module="props.row" />
          </template>
        </el-table-column> -->
        <el-table-column
          fixed="right"
          label="Ações"
          width="320"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
            <ModuleReleasedAt :module="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Details from "@/admin/Pages/module/components/Details"
import ModuleReleasedAt from "@/admin/Pages/module/components/ModuleReleasedAt"
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  components:{
    Details,
    ModuleReleasedAt,
  },
  mixins: [RequestErrors],
  data() {
    return {
    }
  },
  computed:{
    ...mapGetters(['permissions','isLoadingModules','modules']),
  },
  created(){
    this.getGrades()
  },
  methods:{
    ...mapMutations(['SET_HISTORY', 'SET_MODULE']),
    ...mapActions(['deleteModule', 'disableModule', 'enableModule','getModuleLogs','invalidateModule','getGrades']),
    onDelete(module){
      this.$confirm('Tem certeza que deseja apagar o módulo ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() => this.deleteModule(module).then(()=>this.$emit('changed')))
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
    onEnable(module){
      this.$confirm('Tem certeza que deseja habilitar o módulo?', 'Atenção', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'warning'
        }).then(() => {
          return this.enableModule({ id: module.id })
          .then(()=>{
            this.$message({
              type: 'success',
              message: 'Módulo Habilitado com sucesso'
            })
            this.$emit("changed")
          })
          .catch((e) => {
            this.showDefaultErrorMessages(e);
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada.'
          });
        });
    },
    onDisable(module){
      this.$confirm('Tem certeza que deseja dehabilitar o módulo?', 'Atenção', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'warning'
        }).then(() => {
          return this.disableModule( { id: module.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Módulo desabilitado com sucesso'
            })
            .catch((e) => {
              this.showDefaultErrorMessages(e);
            })
            this.$emit("changed");
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada.'
          });
        });
    }
  },

}
</script>

