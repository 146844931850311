<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Título"
          class="w-100"
          prop="title"
          :error="errors.title"
        >
          <el-input
            v-model="form.title"
            clearable
            name="title"
            show-word-limit
            maxlength="190"
            placeholder="Título"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Conteúdo"
          class="w-100"
          prop="content"
          :error="errors.content"
        >
          <p class="text-danger small">
            * Tamanho máximo de upload de imagem é 2048 KB
          </p>
          <ckeditor
            v-model="form.content"
            :editor="editor"
            @ready="onEditorReady"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="mt-5 mb-2">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";
import editor from "@/admin/utils/Editor";

export default {
  mixins:[ RequestErrors ],
  props: {
    pageId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      editor,
      rules
    }
  },
  computed: {
    ...mapGetters(['page']),
    form: {
      get() {
        return this.$store.state.page.current;
      },
      set(page) {
        this.SET_PAGE(page);
      }
    }
  },
  methods: {
    ...mapMutations(['SET_PAGE']),
    ...mapActions(['createPage','updatePage']),
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const action = this.page.id ? this.updatePage({data: this.form, id: this.page.id}) : this.createPage({data: this.form});
          action
            .then((r) => {
              this.$message({
                message: 'Página salva com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
    onEditorReady(editor) {
      editor.isReadOnly = false;
    }
  },
}
</script>
