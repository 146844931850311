const form = {
  type: 'default',
  name: '',
  description:'',
  subject_id: '',
  trail_id: '',
  topic_id: '',
  thumbnail: '',
  contents: []
}
export default {
  state: {
    list: [],
    current: null,
    isLoadingModules: false,
    form
  },
  actions: {
    getModules({commit, state}, params = "") {
      state.isLoadingModules = true;
      return axios.get(`/admin/module?${params}`)
      .then(r => {
        commit("SET_MODULES", r.data.data);
        return r;
      }).finally(() => state.isLoadingModules = false);
    },
    getModule({commit}, {id}) {
      return axios.get(`/admin/module/${id}`)
      .then(r => {
        commit("SET_MODULE", r.data.data);
        return r;
      });
    },
    createModule({commit}, params) {
      return axios.post(`/admin/module/`, params.data);
    },
    updateModule({commit}, params) {
      return axios.put(`/admin/module/${params.id}`, params.data);
    },
    deleteModule({commit}, {id}) {
      return axios.delete(`/admin/module/${id}`);
    },
    getModuleLogs({commit}, module){
      return axios.get(`/admin/module/${module.id}/logs`)
      .then(({data: {data : logs}}) => {
        commit("SET_HISTORY", {items:logs ,title:`Histórico de Alterações do Item ${ module.name }`});
      })
    },
    enableModule({commit, state}, { id }){
      return axios.post(`/admin/module/enable`, { module_id: id });
    },
    updateReleasedPeriod({commit, state}, data){
      return axios.post(`/admin/module/update-release-period`, data)
      .then(response => {
        const { data: {data: module }} = response;
        commit("SET_MODULES", state.list.map(m =>  {
          if(m.id == module.id){
            return {
              ...m,
              released_at_cycle_order: module.released_at_cycle_order,
              released_at_grade_order: module.released_at_grade_order
            }
          }else{
            return m
          }
        }))
      });
    },
    disableModule({}, { id }){
      return axios.post(`/admin/module/disable`, { module_id: id });
    }
  },
  mutations: {
    SET_MODULES(state, payload) {
      state.list = payload;
    },
    SET_MODULE(state, payload) {
      state.current = payload;
      state.form = payload ? payload : form;
    }
  },
  getters:{
    modules: state => state.list,
    module: state => state.current,
    isLoadingModules: state => state.isLoadingModules,
  }
};
