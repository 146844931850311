<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingQuizzes && quizzes.length == 0"
      animated
    >
      <el-table
        :data="quizzes"
        style="width: 100%"
        row-key="id"
      >
        <el-table-column
          label="Id"
          prop="id"
          width="50"
        />
        <el-table-column
          label="Título"
          prop="title"
        />
        <el-table-column
          label="Módulo"
          prop="module.name"
        />
        <el-table-column
          label="Questões"
          prop="count_questions"
        />
        <el-table-column
          fixed="right"
          label="Ações"
          width="320"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  computed:{
    ...mapGetters(['permissions', 'quizzes', 'isLoadingQuizzes']),
  },
  methods:{
    ...mapMutations([]),
  }
}
</script>

