
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    greaterPercentageKey: {
      type: String,
      required: true,
    }
  },
  data(){
    return {
      avg: true,
    }
  },
  computed: {
    ...mapGetters(['cycle_configs','logins_per_cycle','loading_logins_per_cycle']),
    greater_percentage() {
      return _.orderBy(this.logins_per_cycle, [this.greaterPercentageKey], ['desc'])[0][this.greaterPercentageKey];
    },
  },
  created(){
    if(this.logins_per_cycle.length == 0 && !this.loading_logins_per_cycle) {
      this.getLoginsPerCycle()
    }
  },
    methods: {
    ...mapActions(['getLoginsPerCycle']),
    getPercentage(value) {
      if(this.greater_percentage<=0){
        return 0;
      }
      const percentage = (value * 100) / this.greater_percentage;
      return Number(percentage < 1 && percentage > 0 ? 1 : percentage.toFixed(2));
    }
  },

  }
