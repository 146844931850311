<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    label-position="top"
    class="mx-2"
  >
    <el-row :gutter="20">
      <el-col :md="6">
        <el-form-item label="Nome">
          <el-input
            v-model="form.search"
            placeholder="Pesquisar"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Área de interesse">
          <el-select
            v-model="form.trail_id"
            placeholder="Pesquisar por área de interesse"
            clearable
          >
            <el-option
              v-for="(t, index) in trails"
              :key="index"
              :label="t.name"
              :value="t.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Módulos">
          <el-select
            v-model="form.module_id"
            placeholder="filtro por módulos"
            clearable
          >
            <el-option
              v-for="(m, index) in modules"
              :key="index"
              :label="m.name"
              :value="m.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {
        search:'',
        subject_id: '',
        trail_id: '',
        module_id: '',
      },
    }
  },
  computed:{
    ...mapGetters(['modules', 'trails']),
    filters(){
      return `&search=${this.form.search}&subject_id=${this.form.subject_id}&trail_id=${this.form.trail_id}&module_id=${this.form.module_id}`
    }
  },
  watch:{
    filters: {
      handler(){
       this.$emit('input', this.filters)
      },
      immediate: true,
    }
  },
  mounted() {
      this.getModules()
      this.getTrails()
  },
  methods:{
    ...mapActions(['getModules', 'getTrails']),
  }
}

</script>

