export default {
    state: {
        list: [],
        selection_list: [],
        current: null,
        isLoadingChallenges: false,
    },
    actions: {
        getChallenges({commit}, params) {
            this.isLoadingChallenges = true;
            return axios.get(`/admin/challenge?${params}`)
            .then(r => {
                commit("SET_CHALLENGES", r.data.data.map(challenge => {
                    return  {
                        ...challenge,
                        questions: _.orderBy(challenge.questions,['is_theme','module_id','order'],['desc', 'desc','asc'])
                        .map((q,index) => {
                          return {
                            ...q,
                            key: index,
                          }
                        })
                      };
                }));
                return r;
            })
            .finally(()=>this.isLoadingChallenges = false);
        },
        getChallengesLogs({commit}, challenge){
          return axios.get(`/admin/challenge/${challenge.id}/logs`)
          .then( response => {
            commit('SET_HISTORY', {items:response.data.data ,title:`Histórico de Alterações do Item ${ challenge.description }`});
            return response;
          })
        },
        updateChallenge(store, {id, data}) {
            return axios.put(`/admin/challenge/${id}`, data);
        },
        resetChallenge(state, {data}) {
            return axios.post(`/admin/challenge/reset`, data);
        },
        deleteChallengesByIds(store, { challenge_ids }){
            return axios.post(`/admin/maintenance/challenge/delete-by-ids`, {
                ids: challenge_ids
            })
        }
    },
    mutations: {
        SET_CHALLENGES(state, payload) {
            state.list = payload.map(s=> {
                if(state.selection_list.find((sl) => sl.id == s.id)){
                    s.selected = true
                }else {
                  s.selected = false
                }
                return s
            });
        },
        SET_CHALLENGE_SELECTION_LIST(state, payload) {
            state.selection_list = payload;
        },
        CLEAR_CHALLENGE_SELECTION_LIST(state) {
            state.selection_list = [];
            state.list = state.list.map(s=>{ s.selected = false; return s } )
        },
    },
    getters: {
        challenges: state => state.list,
        challenge_selection_list: state => state.selection_list,
        challenge: state => state.current,
        isLoadingChallenges: state => state.isLoadingChallenges,
    }
};
