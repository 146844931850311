export default {
  title: [
    { required: true, message: 'O título do vídeo é obrigatório.', trigger: 'blur' },
    { min: 10, max: 190, message: 'O título deve ter entre 10 e 190 caracteres.', trigger: 'blur' }
  ],
  content: [
    { required: true, message: 'O campo link do vídeo é obrigatório.', trigger: 'blur' },
    { min: 10, max: 190, message: 'O link do vídeo deve ter entre 10 e 190 caracteres.', trigger: 'blur' }
  ],
}
