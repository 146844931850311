<template>
  <el-form
    ref="form"
    :model="form"
    :rules="FormRules"
    class="container-fluid mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Título"
          class="w-100"
          prop="title"
          :error="errors.title"
        >
          <el-input
            v-model="form.title"
            clearable
            name="title"
            show-word-limit
            maxlength="190"
            placeholder="Título"
          />
        </el-form-item>
      </el-col>
      <el-col v-if="scorm">
        <el-form-item
          label="Link do scorm"
          class="w-100"
        >
          <el-input
            v-model="form.url"
            clearable
            name="embed"
            readonly
            placeholder="www.linkdoscorm.com/nome-do-scorm"
            disabled
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Pacote do scorm"
          class="w-100"
          prop="file"
          :error="errors.file"
        >
          <input
            id="file_field"
            ref="file_field"
            type="file"
            @change="onChangeFile"
          >
        </el-form-item>
      </el-col>
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters } from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import FormRules from "./FormRules";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      FormRules
    }
  },
  computed: {
    ...mapGetters(['scorm']),
    form: {
      get() {
        return this.$store.state.scorm.current;
      },
      set(scorm) {
        this.SET_SCORM(scorm);
      }
    }
  },
  mounted() {
    if (this.scorm) {
      this.FormRules.file[0].required = false;
    }
  },
  methods: {
    onChangeFile(e){
      this.form.file = e.target.value ? 'file' : ' '
    },
    ...mapActions(['getScorm','createScorm','updateScorm']),
    onSubmit() {

      this.$refs['form'].validate((valid) => {
        if (valid) {

          const action = this.scorm.id ? this.updateScorm({
            data: this.getData(),
            id: this.scorm.id
          }) : this.createScorm({data: this.getData()});
          this.$loading();
          action
            .then(() => {
              this.$message({
                message: 'Scorm salvo com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=> this.showDefaultErrorMessages(e))
            .finally(()=>this.$loading().close());
          } else {
          return false;
        }
      });
    },
    setForm(scorm) {
      this.form = scorm;
    },
    getData() {
      const data =  new FormData();
      data.append('title', this.form.title);
      if(document.getElementById('file_field').files.length){
        data.append('file', document.getElementById('file_field').files[0])
      }
      if (this.scorm && this.scorm.id)
        data.append('_method','put');
      return data;
    }
  },
}
</script>
