<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          :can-create="false"
        />
        
        <FilterList v-model="search" />

        <List @changed="getTickets(filters)">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-ticket')"
                icon="el-icon-edit"
                size="mini"
                type="primary"
                @click="onEdit(row)"
              >
                Alterar status
              </el-button>
              <el-button
                v-if="permissions.includes('delete-ticket')"
                icon="el-icon-delete"
                size="mini"
                type="danger"
                @click="onDelete(row)"
              >
                Apagar
              </el-button>
            </el-button-group>
          </template>
        </List>

        <div class="d-flex justify-content-center p-2">
          <el-pagination
            :current-page.sync="meta.current_page"
            :total="meta.total"
            background
            layout="prev, pager, next"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/ticket/components/List"
import FilterList from "@/admin/Pages/ticket/components/FilterList"
import {mapActions, mapGetters} from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default {
  components: {
    List,
    FilterList
  },
  data() {
    return {
      registering: false,
      search: '',
      meta: {
        current_page: 1,
        total: 10
      },
    }
  },
  computed: {
    ...mapGetters(['permissions','ticket_types']),
    filters() {
      return `${this.search}&page=${this.meta.current_page}`
    },
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this._timerId)
        this._timerId = setTimeout(() => {
          this.$loading(defaultLoadingConfig);
          this.getTickets(this.filters)
            .then((r) => {
              this.meta = r.data.meta;
            })
            .finally(() => this.$loading().close())
        }, 500)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      'getTickets',
      'getTicket',
      'updateTicket',
      'deleteTicket'
    ]),

    onEdit(ticket) {
      let message = ticket.solved_at ? 'Deseja marcar o ticket como em análise?' : 'Deseja marcar o ticket como resolvido?';
      this.$confirm(message, 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
        .then(() => this.updateTicket({id: ticket.id, solved: !ticket.solved}).then(() => {
          this.getTickets(this.filters);
          this.$message({
            message: 'Status alterado com sucesso.',
            type: 'success',
            showClose: true
          })
        }))
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada!'
          });
        });
    },

    onDelete(ticket) {
      this.$confirm('Tem certeza que deseja apagar o ticket ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
        .then(() => this.deleteTicket(ticket).then(() => {
          this.getTickets(this.filters);
          this.$message({
            message: 'Ticket apagado com sucesso.',
            type: 'success',
            showClose: true
          })
        }))
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada!'
          });
        });
    },
  },
}
</script>

<style scoped>
.button-link {
  border: none;
}

.text-link {
  font-size: 12px;
}
</style>
