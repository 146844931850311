export default {
    state: {
        list: [],
        current: null,
        isLoadingChallengeQuestions: false,
    },
    actions: {
        getChallengeQuestion({commit}, params) {
            return axios.get(`/admin/challenge-question/${params.data.id}`)
            .then(r => {
                commit("SET_CHALLENGE", r.data.data);
                return r
            });
        },
        getChallengeQuestions({commit}, params) {
            this.isLoadingChallengeQuestions = true;
            return axios.get(`/admin/challenge-question?${params}`)
            .then(r => {
                commit("SET_CHALLENGE_QUESTIONS", r.data.data);
                return r;
            })
            .finally(()=>this.isLoadingChallengeQuestions = false);
        },
        getChallengeQuestionsLogs({commit}, challenge){
          return axios.get(`/admin/challenge-question/${challenge.id}/logs`)
          .then( response => {
            commit('SET_HISTORY', {items:response.data.data ,title:`Histórico de Alterações do Item ${ challenge.description }`});
            return response;
          })
        },
        createChallengeQuestion(store, {data}) {
            return axios.post(`/admin/challenge-question/`, data);
        },
        updateChallengeQuestion(store, {id, data}) {
            return axios.put(`/admin/challenge-question/${id}`, data);
        },
        deleteChallengeQuestion(store, challenge){
            return axios.delete(`/admin/challenge-question/${challenge.id}`);
        },
        enableChallengeQuestion(store, question_id){
          return axios.post(`/admin/challenge-question/enable`, { question_id })
        },
        disableChallengeQuestion(store, question_id){
          return axios.post(`/admin/challenge-question/disable`, { question_id })
        },
        invalidateChallengeQuestion(store, question_id){
          return axios.post(`/admin/challenge-question/invalidate`, { question_id })
        },
    },
    mutations: {
        SET_CHALLENGE_QUESTION(state, payload) {
            state.current = payload;
        },
        SET_CHALLENGE_QUESTIONS(state, payload) {
            state.list = payload;
        },
    },
    getters: {
        challenge_questions: state => state.list,
        challenge_question: state => state.current,
        isLoadingChallengeQuestions: state => state.isLoadingChallengeQuestions,
    }
};
