export default {
    state: {
        list: [],
        current: null,
        isLoadingScorms: false,
    },
    actions: {
        getScorms({commit, state}, params = '') {
            state.isLoadingScorms = true;
            return axios.get(`/admin/scorm?${params}`)
            .then(r => {
              const  {data: { data: scorms }} = r
              commit("SET_SCORMS", scorms);
              return r
            })
            .finally(()=>state.isLoadingScorms = false);
        },
        getScorm({commit}, params) {
            return axios.get(`/admin/scorm/${params.data.id}`).then(r => {
                commit("SET_SCORM", r.data.data);
            });
        },
        createScorm({commit}, params) {
            return axios.post(`/admin/scorm/`, params.data, {
                headers: {
                    "Content-Type": `multipart/form-data`
                }
            });
        },
        updateScorm({commit}, params) {
            return axios.post(`/admin/scorm/${params.id}`, params.data, {
                headers: {
                    "Content-Type": `multipart/form-data`
                }
            });
        },
        deleteScorm({commit}, scorm){
            return axios.delete(`/admin/scorm/${scorm.id}`);
        }
    },
    mutations: {
        SET_SCORMS(state, payload) {
            state.list = payload;
        },
        SET_SCORM(state, payload) {
            state.current = payload;
        }
    },
    getters: {
      scorms: state => state.list,
      scorm: state => state.current,
      isLoadingScorms: state => state.isLoadingScorms,
    }
};
