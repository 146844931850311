/* eslint-disable no-undef */
import Vuex from "vuex";

window.Vue = require('vue');

/**END PAGES */

Vue.component("NavbarH", require('@/admin/layouts/components/NavbarH').default)
Vue.component("PageHeader", require('@/admin/components/PageHeader').default)
Vue.component("NavbarV", require('@/admin/layouts/components/NavbarV').default)
Vue.component("DefaultLayout", require('@/admin/layouts/DefaultLayout').default)

Vue.component("ButtonHistory", require('./components/BtnHistory').default)

Vue.component("ModalHistoryStatus", require('./components/ModalHistoryStatus').default)

Vue.filter("subStr", (string, param) => {
    return string.length > param ? string.substring(0, param) + '...' : string;
});

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.use(VueMoment, {
    moment,
})

Vue.use(Vuex);

Vue.filter("date", (string, param) => {
    return string.toLocaleString('pt-BR');
});

import store from './store/store';

import routes from "./routes/index.js";
import VueRouter from 'vue-router';
Vue.use(VueRouter)

import { isInDevelop, isNotInDevelop } from "@/constants/general";

import Highcharts from "@/plugins/Highcharts.js";

Vue.mixin({
  data() {
    return {
      isInDevelop,
      isNotInDevelop,
    }
  }
})


require('../plugins/Element');

new Vue({
    el: '#app',
    store,
    router: routes,
});

