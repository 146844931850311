<template>
  <draggable
    v-model="contents"
    @change="$emit('input', contents)"
  >
    <transition-group class="transition">
      <el-row
        v-for="(content, index) in contents"
        :key="content.title"
        class="border p-2"
      >
        <el-col :md="20">
          <el-tag size="medium">
            {{ index + 1 }}°
          </el-tag>
          {{ content.title | subStr(100) }}
        </el-col>
        <el-col :md="4">
          <div>
            <el-tag 
              v-if="content.is_video"
              type="info"
              size="medium"
            >
              Vídeo
            </el-tag>
            <el-tag 
              v-if="content.is_scorm"
              type="warning"
              size="medium"
            >
              Scorm
            </el-tag>
            <el-tag 
              v-if="content.quiz_id"
              type="success"
              size="medium"
            >
              Questionário
            </el-tag>
            
            <el-tag 
              v-if="content.is_page"
              size="medium"
            >
              Page
            </el-tag>
            <i 
              class="el-tag__close el-icon-close mx-3"
              @click="$emit('remove', content)"
            />
          </div>
        </el-col>
      </el-row>
    </transition-group>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  computed:{
    contents:{
      get(){
        return this.$store.state.module.form.contents;
      },
      set(value){
        this.$store.state.module.form.contents = value;
      }
    }
  }
}
</script>
