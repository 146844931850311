<template>
  <div>
    <p>
      <span class="font-weight-bold">Nome:</span> {{ themeBlock.name }}
    </p>
    <p>
      <span class="font-weight-bold">Descrição:</span> {{ themeBlock.description }}
    </p>
  </div>
</template>
<script>
  export default {
    props:{
      themeBlock: {
        type: Object,
        required: true,
      }
    }
  }
</script>

