<template>
  <el-button
    size="mini"
    type="primary"
    icon="el-icon-s-order"
    @click="$refs.fileinput.click()"
  >
    Prova
    <input 
      ref="fileinput" 
      type="file"
      class="d-none" 
      @change="onChange"
    >
  </el-button>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        module: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters(['modules','themes']),
    },
    methods: {
        ...mapMutations(['SET_MODULES',]),
        onChange(){
            this.$loading({
                text: 'Salvando arquivo de prova do módulo'
            });
            const formData = new FormData()
            formData.append('file', this.$refs.fileinput.files[0])
            axios.post(`/admin/module/${this.module.id}/upload-exam`, formData , {
              headers: {
                "Content-Type": `multipart/form-data`
              }
            })
            .then(({data: {data: module }}) => {
              this.SET_MODULES(this[this.module.type === 'theme' ? 'themes': 'modules'].map(m => {
                if(m.id == module.id) m.exam_file = module.exam_file 
                return m
              }))
                this.$message({
                  type: 'success',
                  message: 'Carregamento da prova realizado com sucesso'
                })
            })
            .catch(() => {
              this.$message({
                  type: 'error',
                  message: 'Erro ao carregar prova, certifique-se que o arquivo é um pdf'
                })
            })
            .finally(() => this.$loading().close())
        }
    }
}
</script>