<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />

        <el-dialog
          :title="page && page.id ? 'Editar página' : 'Cadastrar página'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getPages(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>
        <el-dialog
          :title="`${ page ? page.title : ''}`"
          width="30%"
          class="with-border"
          :visible.sync="showing"
          center
          @closed="SET_PAGE(null)"
        >
          <div
            v-if="showing && page"
            v-html="page.content"
          />
        </el-dialog>

        <el-row :gutter="4">
          <el-col :md="6">
            <el-input
              v-model="search"
              placeholder="Pesquisar"
              clearable
            />
          </el-col>
        </el-row>

        <List @changed="getPages(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-page')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                size="mini"
                type="info"
                icon="el-icon-view"
                @click="onShow(row)"
              >
                Visualizar
              </el-button>
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/page/components/List"
import Register from "@/admin/Pages/page/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','page']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getPages(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getPages','getPage']),
        ...mapMutations(['SET_PAGE']),
        onEdit(page){
          this.registering = true;
          this.SET_PAGE(page)
        },
        onCreate(){
            this.SET_PAGE({
              title: '',
              content: ''
            });
            this.registering = true;
        },
        onShow(page){
          this.SET_PAGE(page)
          this.showing = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_PAGE(null);
        },
    },
}
</script>
