<template>
  <el-table
    :data="modules"
    style="width: 100%"
    :row-class-name="tableRowClassName"
  >
    <el-table-column
      prop="module_order"
      label="Ordem"
      width="100"
    />
    <el-table-column
      prop="name"
      label="Nome"
    />
    <el-table-column
      prop="subject_name"
      label="Carreira/Disciplina"
    />
    <el-table-column
      prop="block_name"
      label="Bloco temático"
    />
  </el-table>
</template>
<script>


export default {
  props:{
    modules:{
      type:Array,
      required: true,
    }
  },
  data(){
    return {
      editModules:[]
    }
  },
  methods:{
    tableRowClassName({ row }){
      return row.block_id ? 'bg-danger text-white': 'bg-primary text-white' 
    }
  }
}
</script>
