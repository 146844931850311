<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader
          slot="header"
          :can-create="permissions.includes('create-pre-cycle-module')"
          @onCreate="onCreate"
        />
        <div>
          <el-dialog
            :title="pre_cycle_module ? 'Editar módulo' : 'Cadastrar módulo'"
            :visible.sync="registering"
            fullscreen
            center
          >
            <Register
              @onSave="getPreCycleModules(filters); onCancel()"
              @cancel="onCancel()"
            />
          </el-dialog>
          <el-dialog
            :title="`Informação do módulo ${ pre_cycle_module ? pre_cycle_module.name : ''}`"
            width="30%"
            class="with-border"
            :visible.sync="showing"
            fullscreen
            center
          >
            <PreviewModule
              v-if="showing && pre_cycle_module"
              :module="pre_cycle_module"
            />
          </el-dialog>
          <FilterList v-model="search" />
        </div>

        <List @changed="getPreCycleModules(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-pre-cycle-module')"
                size="mini"
                icon="el-icon-edit"
                :disabled="!!row.invalidated_at"
                title="Editar módulo"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                size="mini"
                type="info"
                icon="el-icon-view"
                @click="onShow(row)"
              >
                Visualizar
              </el-button>
              <UploadExamButton :module="row" />
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/pre_cycle_module/components/List"
import FilterList from "@/admin/Pages/pre_cycle_module/components/FilterList"
import UploadExamButton from "@/admin/components/UploadModuleExamButton"
import Register from "@/admin/Pages/pre_cycle_module/components/Register"
import PreviewModule from "@/admin/Pages/module/components/PreviewModule"
import { mapActions, mapMutations, mapGetters } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
        PreviewModule,
        FilterList,
        UploadExamButton
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','pre_cycle_module']),
      filters(){
        return `${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
                this.getPreCycleModules(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                })
                .finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getPreCycleModules','getPreCycleModule']),
        ...mapMutations(['SET_PRE_CYCLE_MODULE']),
        onEdit(module){
          this.getPreCycleModule({id: module.id}).then(()=> this.registering = true);
        },
        onCreate(){
            this.SET_PRE_CYCLE_MODULE(null);
            this.registering = true;
        },
        onShow({ id }){
          this.getPreCycleModule({ id }).then(() => this.showing = true);
        },
        onCancel(){
            this.registering = false;
            this.SET_PRE_CYCLE_MODULE(null);
        },
    },
}
</script>
