<template>
  <el-row class="mx-5">
    <el-col>
      <el-card
        shadow="never"
        :body-style="{ padding: '0px' }"
      >
        <PageHeader
          slot="header"
          :can-create="false"
        />
        
        <Filters v-model="filters" />

        <el-skeleton
          :rows="10"
          :loading="isLoadingStudents && students.length == 0"
          animated
        >
          <el-table
            ref="tableData"
            :data="students_by_cycle"
            style="width: 100%"
            row-key="id"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="p-4">
                  <el-row>
                    <el-col>
                      <ListModules 
                        :modules="props.row.selected_modules"
                      />
                    </el-col>
                  </el-row>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="RA"
              prop="identifier"
              width="120"
            />
            <el-table-column
              label="ID"
              prop="user_id"
            />
            <el-table-column
              label="Nome"
              prop="name"
            />
            <el-table-column
              label="Tipo de desafio"
              prop="challenge_type"
            />
            <el-table-column
              fixed="right"
              label="Ações"
              width="220"
            >
              <template slot-scope="{ row }">
                <el-button-group>
                  <el-button
                    v-if="row.should_select_modules"
                    :type="row.has_selected_themes ? 'primary' : 'danger'"
                    size="mini"
                    :disabled="Boolean(school_cycle_grade_config.is_generating_modules && row.has_selected_themes)"
                    @click="onClick(row)"
                  >
                    {{ row.has_selected_themes ? 'Atribuir módulos' : 'Atribuir blocos' }}
                  </el-button>
                  <el-button
                    v-else
                    size="mini"
                    @click="$refs.tableData.toggleRowExpansion(row)"
                  >
                    Visualizar módulos
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>
        
        <div :class="`d-flex justify-content-center p-2`">
          <el-pagination
            background
            :page-sizes="[10, 30, 50, 100, 200, 500]"
            layout="sizes, prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
            :page-size.sync="meta.per_page"
          />
        </div>
      </el-card>
      <el-dialog
        v-if="!!student_by_cycle"
        :visible.sync="showModal"
        width="40%"
      >
        <template slot="title" >
          <p class="font-weight-bold">
            {{ `Definir módulos para ${ student_by_cycle.name } no ${student_by_cycle.cycle_grade_config.cycle_name} ` }} 
          </p>
        </template>
        <SetupStudentModules
          :user="student_by_cycle"
          @onCancel="showModal = false"
          @finished="showModal = false"
        />
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>

import Filters from "@/admin/Pages/maintenance/setup_modules/components/Filters"
import ListModules from "@/admin/Pages/maintenance/setup_modules/components/ListModules"
import SetupStudentModules from "@/admin/Pages/maintenance/setup_modules/components/SetupStudentModules"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { defaultLoadingConfig } from "@/admin/constants/general"
import RequestErrors from "@/admin/utils/RequestErrors";

export default {
  components: {
    Filters,
    ListModules,
    SetupStudentModules,
  },
  mixins:[RequestErrors],
    data() {
        return {
          userCycle: null,
          filters:'',
          showModal: false,
          meta : {
              current_page: 1,
              total: 10,
              per_page: 50,
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','students_by_cycle', 'student_by_cycle','isLoadingStudents', 'school_cycle_grade_config']),
      params(){
        return `${this.filters}&page=${this.meta.current_page}&per_page=${this.meta.per_page}&order_by=name&order_direction=asc&not-selected-themes`
      },
    },
    watch:{
      params: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getStudentsByCycle(this.params)
                .then((r)=> {
                    const { data :{meta} } = r
                    this.meta.per_page = Number(meta.per_page);
                    this.meta.current_page = meta.current_page;
                    this.meta.total = meta.total;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
      },
    },
    methods:{
        ...mapMutations(['SET_STUDENT_CYCLE']),
        ...mapActions(['getStudentsByCycle','studentCycle']),
       onClick(userCycle){
        this.SET_STUDENT_CYCLE(userCycle);
        this.showModal = true
      }
    },
}
</script>
