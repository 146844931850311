<template>
  <el-form
    ref="form"
    :model="form"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="10">
      <el-col
        :md="6"
        :lg="6"
      >
        <el-form-item label="Tipos">
          <el-select
            v-model="form.ticketable_type"
            class="w-100"
            placeholder="selecione o tipo"
          >
            <el-option
              v-for="(type, index) in [...ticket_types, {name: 'Todos', value: '', count: ticket_types.length ? ticket_types.map(v=>v.count).reduce((a,b)=>a + b):0}]"
              :key="index"
              :label="`${type.name} (${type.count})`"
              :value="type.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        :md="6"
        :lg="6"
      >
        <el-form-item label="Dispositivo">
          <el-select
            v-model="form.device"
            class="w-100"
            placeholder="Selecione o dispositivo"
            clearable
          >
            <el-option
              v-for="(device, index) in devices"
              :key="index"
              :value="device"
              :label="device"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col
        :md="6"
        :lg="6"
      >
        <el-form-item label="Status">
          <el-radio-group
            v-model="form.solved"
            size="medium"
          >
            <el-radio :label="false">
              Em análise
            </el-radio>
            <el-radio :label="true">
              Concluído
            </el-radio>
            <el-radio label="">
              Todos
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      ticketableTypes: [
        {
          model: '',
          label: 'Todos',
        },
        {
          model: 'quiz',
          label: 'Quiz',
        },
        {
          model: 'video',
          label: 'Vídeo',
        },
        {
          model: 'scorm',
          label: 'Scorm',
        },
        {
          model: 'link',
          label: 'Link',
        },
      ],
      devices: [
        'Desktop (computador)',
        'Notebook',
        'iPad',
        'Tablet Android',
        'iPhone',
        'Smartphone Android',
        'Outro'
      ],
      form: {
        ticketable_type: '',
        solved: '',
        device: '',
      },
    }
  },
  computed: {
    ...mapGetters(['ticket_types']),
    filters() {
      return `${this.form.ticketable_type ? `&ticketable_type=${this.form.ticketable_type}` : '' }${this.form.solved === true || this.form.solved === false ?  `&solved=${this.form.solved}`: ''}&device=${this.form.device}`
    }
  },
  watch: {
    filters: {
      handler() {
        this.$emit('input', this.filters)
      },
      immediate: true,
    }
  },
}
</script>
