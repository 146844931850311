<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col :md="6">
        <el-form-item label="Nome">
          <el-input
            v-model="form.search"
            placeholder="Pesquisar"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :md="6">
        <el-form-item label="Carreiras">
          <el-select
            v-model="form.trail_id"
            clearable
            placeholder="Selecione a trilha"
            filterable
          >
            <el-option
              v-for="(trail, index) in trails"
              :key="index"
              :label="`${trail.name} ${trail.active ? `(${trail.count_blocks})`: `(${trail.count_blocks})(inativa)`}`"
              :value="trail.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {
        search:'',
        trail_id: '',
      },
    }
  },
  computed:{
    ...mapGetters(['trails']),
    filters(){
      return `&search=${this.form.search}&trail_id=${this.form.trail_id}`
    }
  },
  watch:{
    filters: {
      handler(){
        this.respondToRouteChanges = false;
        this.$router.replace({query: { ...this.form }})
            .finally(() => {
              this.respondToRouteChanges = true;
            })
        this.$emit('input', this.filters)
      },
    }
  },
  created(){
    if(this.$route.query.search && this.form.search.length == 0){
      this.form.search = this.$route.query.search
    } 
    if(this.trails.length == 0){
      this.getTrails();
    }
  },
  methods:{
    ...mapActions(['getTrails',]),
  }
}

</script>

