export default {
    state: {
        list: [],
        current: null,
        isLoadingSubjects: false

    },
    actions: {
        getSubjects({ commit, state }, params = '') {
          state.isLoadingSubjects = true;
            return axios.get(`/admin/subject/${params}`).then(r => {
                commit("SET_SUBJECTS", r.data.data);
                return r;
            })
          .finally(()=>state.isLoadingSubjects = false);
        },
        getSubject({ commit }, params) {
            return axios.get(`/admin/subject/${params.data.id}`).then(r => {
                commit("SET_SUBJECT", r.data.data);
                return r;
            });
        },
        createSubject({ commit }, params) {
            return axios.post(`/admin/subject/`, params.data);
        },
        updateSubject({ commit }, params) {
            return axios.post(`/admin/subject/${params.id}`, params.data, {
              headers: {
                "Content-Type": `multipart/form-data`
              }
            });
        },
        deleteSubject({ commit }, params) {
            return axios.delete(`/admin/subject/${params.id}`);
        }
    },
    mutations: {
        SET_SUBJECT(state, payload) {
            state.current = payload;
        },
        SET_SUBJECTS(state, payload) {
            state.list = payload;
        }
    },
    getters:{
      subjects: state => state.list.filter(s=>s.type == 'default'),
      all_subjects: state => state.list,
      pre_cycle_subjects: state => state.list.filter(s=>s.type == 'pre_cycle'),
      subject: state => state.current,
      isLoadingSubjects: state => state.isLoadingSubjects,
    }
};
