<template>
  <el-form
    ref="form"
    v-model="form"
    :rules="rules"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="10">
      <el-col :md="8">
        <el-form-item
          label="Escola"
          prop="school_id"
        >
          <el-select
            v-model="form.school_id"
            :loading="isLoadingSchools"
            placeholder="Escola"
            filterable
            class="w-100"
          >
            <el-option
              v-for="school in schools.filter(s=>s.count_active_students > 0)"
              :key="school.id"
              :label="`${school.name} (${school.count_active_students} alunos)`"
              :value="school.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          label="Série"
          prop="grade_id"
        >
          <el-select
            v-model="form.grade_id"
            placeholder="Série"
            class="w-100"
          >
            <el-option
              v-for="grade in grades"
              :key="grade.id"
              :label="grade.name"
              :value="grade.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item
          label="Ciclo"
          prop="order"
        >
          <el-select
            v-model="form.order"
            placeholder="Ciclo"
            class="w-100"
          >
            <el-option
              v-for="cycle in cycle_configs.filter(c =>c.year >= 2023 && c.order > 0)"
              :key="cycle.id"
              :label="`${cycle.cycle_name}`"
              :value="cycle.order"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
     form: {
      order: '',
      grade_id: null,
      school_id: null,
     },
     rules: {
          order: [
            { required: true, message: 'Selecione um ciclo', trigger: 'blur' },
          ],
          grade_id: [
            { type: 'number', required: true, message: 'Selecione ao menos uma série', trigger: 'blur' },
          ],
          school_id: [
            { type: 'number', required: true, message: 'Selecione ao menos uma escola', trigger: 'blur' },
          ],
        }
      }
    },
  computed: {
    ...mapGetters(['schools','cycle_configs','grades','isLoadingSchools']),
    filters(){
      return `&order=${this.form.order}&grade_id=${this.form.grade_id}&school_id=${this.form.school_id}`
    }
  },
  watch:{
    filters(){
        if (this.form.order && this.form.grade_id && this.form.school_id) {
          this.$emit('onEdit',this.filters)
        }
    },
  },
  mounted(){
    if(this.schools.length == 0 ){
      this.getSchools('order_by=name&order_direction=asc&per_page=1000');
    }
    if(this.grades.length == 0 ){
      this.getGrades()
    }
    this.getCycleConfigs()
  },
  methods:{
    ...mapActions(['getSchools','getCycleConfigs','getGrades']),
  },
}
</script>
