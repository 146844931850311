<template>
  <el-form
    ref="form"
    :model="form"
    class="mb-2"
    label-position="top"
    :rules="rules"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Descrição/Título"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            clearable
            name="description"
            show-word-limit
            maxlength="190"
            placeholder="Título"
          />
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item
          label="Módulo"
          prop="module_id"
          :error="errors.module_id"
        >
          <el-select
            v-model="form.module_id"
            filterable
            placeholder="Módulo"
            class="w-100"
          >
            <el-option
              v-for="item in modules.concat(themes)"
              :key="item.id"
              :label="`${item.name} (${item.block_id ? 'Carreira': ''})`"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item
          label="Vestibular"
          class="w-100"
          prop="exam_name"
          :error="errors.exam_name"
        >
          <el-input
            v-model="form.exam_name"
            clearable
            name="exam_name"
            show-word-limit
            maxlength="190"
            placeholder="Vestibular"
          />
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item
          label="Enunciado"
          class="w-100"
        >
          <p class="text-danger small">
            * Tamanho máximo de upload de imagem é 2048 KB
          </p>
          <ckeditor
            v-model="form.content"
            :editor="editor"
          />
        </el-form-item>
        <p
          v-if="errors.content"
          class="text-danger small"
        >
          {{ errors.content }}
        </p>
      </el-col>

      <el-col>
        <AnswerList
          :editing="!!form.id"
          @change="$refs['saveButton'].$el.scrollIntoView({ behavior: 'smooth' }), $refs['saveButton'].$el.focus()" 
        />
        <p
          v-if="errors.answers"
          class="text-danger small"
        >
          {{ errors.answers }}
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button
          ref="saveButton"
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations } from "vuex";
import RequestErrorsMixins from "@/admin/utils/RequestErrors.js"
import AnswerList from "@/admin/Pages/question/components/AnswerList.vue";
import rules from "./FormRules.js"
import editor from "@/admin/utils/Editor.js";

export default {
  components:{ AnswerList },
  mixins: [RequestErrorsMixins],
  data() {
    return {
      editor,
      rules,
      errors:{},
    }
  },
  computed: {
    ...mapGetters(['modules','themes']),
    form:{
      set(value){
        this.SET_QUESTION(value);
      },
      get(){
        return this.$store.state.question.current
      }
    }
  },
  mounted() {
    if(this.modules.length <= 15) {
      this.getModules();
    }
    if(this.themes.length <= 15) {
      this.getThemes();
    }
  },
  methods: {
    ...mapMutations(['SET_QUESTION']),
    ...mapActions(['createQuestion','updateQuestion','getModules','getThemes']),
    onSubmit() {

      this.$refs['form'].validate((valid) => {

        if (valid) {
          this.$loading();
          const action = this.form.id ? this.updateQuestion({
            data: this.form,
            id: this.form.id
          }) : this.createQuestion({data: this.form});
          action
            .then(() => {
              this.$message({
                message: 'Questão salva com sucesso.',
                type: 'success',
              });
              this.$emit('saved');
            })
            .catch(e=>this.showDefaultErrorMessages(e))
            .finally(() => this.$loading().close());
          } else {
            this.$message({
                message: 'Verifique os campos para continuar.',
                type: 'error',
              });
        }
      });
    },
  },
  

}
</script>
