<template>
  <el-form
    ref="form"
    :model="form"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="10">
      <el-col :md="3">
        <el-form-item label="Plataforma">
          <el-select
            v-model="form.platformId"
            placeholder="Plataforma"
            class="w-100"
          >
            <el-option
              v-for="(platform, key) in platforms"
              :key="key"
              :label="platform.name"
              :value="platform.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="3">
        <el-form-item label="Escola">
          <el-select
            v-model="form.school_id"
            placeholder="Escola"
            clearable
            filterable
            class="w-100"
          >
            <el-option
              v-for="(loop_school, key) in all_schools.filter(s=>s.count_active_students >0).filter(s=>s.platformId == form.platformId)"
              :key="key"
              :label="loop_school.search"
              :value="loop_school.id"
            >
              {{ `${loop_school.name} (${loop_school.count_active_students} alunos)` }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        v-if="form.platformId == 4"
        :md="2"
      >
        <el-form-item label="Ciclo">
          <el-select
            v-model="form.cycle_config_id"
            placeholder="Ciclo"
            clearable
            class="w-100"
          >
            <el-option
              v-for="(loop_cycle, key) in cycle_configs"
              :key="key"
              :label="`${loop_cycle.cycle_name} (${loop_cycle.year})`"
              :value="loop_cycle.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="2">
        <el-form-item label="Série">
          <el-select
            v-model="form.grade_id"
            placeholder="Série"
            clearable
          >
            <el-option
              v-for="(loop_grade, key) in grades"
              :key="key"
              :label="loop_grade.name"
              :value="loop_grade.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="2">
        <el-form-item label="Filtrar">
          <el-button
            icon="el-icon-search"
            type="primary"
            @click="$emit('input', filters)"
          >
            Filtrar
          </el-button> 
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item :label="`${form.platformId == 4 ? 'Apagar' : 'Reiniciar'} resultados filtrados`">
          <BtnDeleteBySchoolCycleGrade 
            v-if="form.platformId == 4"
            :cycle="cycle"
            :school="school"
            :grade="grade"
            @deleted="$emit('deleted')"
          />
          <BtnRestartBySchoolCycleGrade 
            v-else
            :cycle="cycle"
            :school="school"
            :grade="grade"
            @restarted="$emit('restarted')"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import BtnDeleteBySchoolCycleGrade from './BtnDeleteBySchoolCycleGrade.vue'
import BtnRestartBySchoolCycleGrade from './BtnRestartBySchoolCycleGrade.vue'

export default {
  components: { BtnDeleteBySchoolCycleGrade , BtnRestartBySchoolCycleGrade },
  data() {
    return {
      form: {
        cycle_config_id: '',
        grade_id: '',
        school_id: '',
        platformId: 4,
      },
      platforms :[
        {
          name: 'Nitro',
          id: 4,
        },
        {
          name: 'Listas',
          id: 10,
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['student','students','all_schools','cycle_configs','grades','permissions']),
    filters() {
      let filters = `&cycle_config_id=${this.form.cycle_config_id}&school_id=${this.form.school_id}&grade_id=${this.form.grade_id}&platformId=${this.form.platformId}`
      return filters;
    },
    cycle(){
      return this.cycle_configs.find(c=>c.id == this.form.cycle_config_id)
    },
    grade(){
      return this.grades.find(g=>g.id == this.form.grade_id)
    }, 
    school(){
      return this.all_schools.find(s=>s.id == this.form.school_id)
    }
  },
  watch:{
    "form.platformId" : {
      handler(){
        this.form.school_id = ''
      }
    }
  },
  mounted(){
    if(this.all_schools.length == 0 ){
      this.getSchools('order_by=name&order_direction=asc&per_page=1000');
    }
    if(this.grades.length == 0 ){
      this.getGrades()
    }
    if(this.cycle_configs.length == 0 ){
      this.getCycleConfigs()
    }
  },
  methods:{
    ...mapActions(['getSchools','getCycleConfigs','getGrades']),
  },
}
</script>
