
export default {
    state: {
        list: [],
        selection_list: [],
        current: null,
        isLoadingStudents: false,
    },
    actions: {
        getStudents({commit, state}, params = '') {
            state.isLoadingStudents = true;
            return axios.get(`/admin/student?${params}`)
            .then(r => {
                const  {data: { data: students}} = r
                commit("SET_STUDENTS", students);
                return r
            })
            .finally(() => state.isLoadingStudents = false);
        },
        getStudent({commit}, params) {
            return axios.get(`/admin/student/${params.data.id}`)
            .then(r => {
                commit("SET_STUDENT", r.data.data);
                return r
            });
        },
    },
    mutations: {
        SET_STUDENT(state, payload) {
            state.current = payload;
        },
        SET_STUDENTS(state, payload) {
            state.list = payload.map(s=> {
                if(state.selection_list.find((sl) => sl.id == s.id)){
                    s.selected = true
                }else {
                  s.selected = false
                }
                return s
            });
        },
        SET_SELECTION_LIST(state, payload) {
            state.selection_list = payload;
        },
        CLEAR_SELECTION_LIST(state) {
            state.selection_list = [];
            state.list = state.list.map(s=>{ s.selected = false; return s } )
        },
    },
    getters:{
      students: state => state.list,
      isLoadingStudents: state => state.isLoadingStudents,
      students_selection_list: state => state.selection_list,
    }
};
