<template>
  <el-row
    class="mx-5"
    :gutter="20"
  >
    <el-col>
      <el-card
        shadow="never"
        :body-style="{ padding: '0px' }"
      >
        <div
          slot="header"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="font-weight-bold">
            Dashboard
          </p>
        </div>
      </el-card>
    </el-col>
    <el-col
      :md="12"
      :lg="12"
    >
      <LoginsPerCycle greater-percentage-key="avg_logins_per_user" />
    </el-col>
    <el-col
      :md="12"
      :lg="12"
    >
      <LoginsPerCycleStudentsWithModules greater-percentage-key="avg_logins_per_user_has_modules_on_cycle"/>
    </el-col>
    <el-col
      :md="12"
      :lg="12"
    >
      <LoginsPerCycleStudentsWithLogins greater-percentage-key="users_percentage_logged_on_cycle"/>
    </el-col>
    <el-col
      :md="12"
      :lg="12"
    >
      <GlobalCycleProgress />
    </el-col>
    <el-col
      :md="12"
      :lg="12"
    >
      <CountSchoolsWithoutModulesOnCycle />
    </el-col>
    <el-col
      :md="12"
      :lg="12"
    >
      <CountStudentsWithFinishedChallengesInsCurrentAndNextCycle />
    </el-col>
    <el-col
      :md="24"
      :lg="24"
    >
      <FullProgressThroughCycles
        v-for="(key) in challenge_full_progress_component_keys"
        :key="key"
        :component-key="key"
        type="challenge"
        @add="challenge_full_progress_component_keys.push(challenge_full_progress_component_keys.length)"
        @remove="value => challenge_full_progress_component_keys = challenge_full_progress_component_keys.filter(i=> i != value)"
      />
    </el-col>
    <el-col
      :md="24"
      :lg="24"
    >
      <FullProgressThroughCycles
        v-for="(key) in cycle_full_progress_component_keys"
        :key="key"
        type="cycle"
        :component-key="key"
        @add="cycle_full_progress_component_keys.push(cycle_full_progress_component_keys.length)"
        @remove="value => cycle_full_progress_component_keys = cycle_full_progress_component_keys.filter(i=> i != value)"
      />
    </el-col>
    <el-col
      :md="12"
      :lg="12"
    >
      <LoginsPerWeek />
    </el-col>
  </el-row>
</template>
<script>

import LoginsPerCycle from './components/LoginsPerCycle.vue'
import LoginsPerCycleStudentsWithModules from './components/LoginsPerCycleStudentsWithModules.vue'
import LoginsPerCycleStudentsWithLogins from './components/LoginsPerCycleStudentsWithLogins.vue'
import CountSchoolsWithoutModulesOnCycle from './components/CountSchoolsWithoutModulesOnCycle.vue'
import CountStudentsWithFinishedChallengesInsCurrentAndNextCycle from './components/CountStudentsWithFinishedChallengesInsCurrentAndNextCycle.vue'
import GlobalCycleProgress from './components/GlobalCycleProgress.vue'
import FullProgressThroughCycles from './components/FullProgressThroughCycles.vue'
import LoginsPerWeek from './components/LoginsPerWeek.vue'
export default {
  components: {
    LoginsPerCycle,
    LoginsPerCycleStudentsWithModules,
    LoginsPerCycleStudentsWithLogins,
    GlobalCycleProgress,
    CountSchoolsWithoutModulesOnCycle,
    CountStudentsWithFinishedChallengesInsCurrentAndNextCycle,
    FullProgressThroughCycles,
    LoginsPerWeek
  },
  data(){
    return {
      challenge_full_progress_component_keys: [0],
      cycle_full_progress_component_keys: [0],
    }
  },
}
</script>
