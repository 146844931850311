<template>
  <el-card
    v-loading="isLoadingCycleConfigs || is_loading_logins_per_week"
    shadow="never"
    :body-style="{ padding: '0px' }"
    class="mt-2"
  >
    <div slot="header">
      <el-row>
        <el-col
          :md="10"
          :lg="10"
        >
          <p class="font-weight-bold mb-0 font-weight-light text-sm">
            WAU (Weekly Active Users)
          </p>
        </el-col>
        <el-col
          :md="9"
          :lg="9"
        >
          <el-button-group>
            <el-button
              size="mini"
              :type="row == 'count_students_logged_on_week' ? 'primary' : 'default'"
              @click="row = 'count_students_logged_on_week'"
            >
              Alunos
            </el-button>
            <el-button
              size="mini"
              :type="row == 'count_logins' ? 'primary' : 'default'"
              @click="row = 'count_logins'"
            >
              Logins
            </el-button>
            <el-button
              size="mini"
              :type="row == 'avg_logins_per_total_active_users_logged_on_week' ? 'primary' : 'default'"
              @click="row = 'avg_logins_per_total_active_users_logged_on_week'"
            >
              Média
            </el-button>
          </el-button-group>
        </el-col>
        <el-col
          :md="5"
          :lg="5"
        >
          <el-select
            v-model="cycle_config_id"
            size="mini"
            placeholder="Selecionar um ciclo"
            class="w-100"
          >
            <el-option
              v-for="item in cycle_configs"
              :key="item.value"
              :label="`Ciclo ${item.order}`"
              :value="item.id"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <highcharts
            v-if="logins_per_week_list.length"
            ref="chart"
            class="hc"
            :options="options"
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>
<script>


import { mapGetters, mapActions, mapMutations } from 'vuex';
 const rows_text = {
  count_students_logged_on_week: ' alunos que logaram na semana',
  count_logins: ' logins na semana',
  avg_logins_per_total_active_users_logged_on_week: ' logins por aluno '
}

export default {
  data() {
    return {
      cycle_config_id: null,
      avg: true,
      row: 'count_students_logged_on_week',
      graphic_type: 'column',
    }
  },

  computed: {
    ...mapGetters(['logins_per_week_list','is_loading_logins_per_week','isLoadingCycleConfigs','cycle_configs','']),
    dataOptions(){
      return this.logins_per_week_list.map(week => {
        return [week.week_name, week[this.row]];
      });
    },
    pointFormat(){
      const text = rows_text[this.row]
      return `<b/>{point.y}</b> ${text}`
    },
    options() {

      const component = this;

      return {
        chart: {
          type: this.graphic_type,
          title: '',
          backgroundColor: 'transparent',
        },
        title: {
          text: '',
        },
        tooltip: {
          headerFormat: '',
          pointFormat: this.pointFormat
        },
        yAxis: {
          title: {
            useHTML: true,
            text: this.avg ? 'Média': 'Total somado'
          }
        },
        xAxis: {
          title: {
            useHTML: true,
            text: 'Ciclos'
          },
          labels: {
            enabled: true,
            formatter: function() {
              return component.dataOptions[this.value][0]
            },
        }
        },
        series: [{
          colorByPoint: true,
          minPointSize: 1,
          innerSize: '20%',
          zMin: 100,
          yMin: 100,
          colors: [
          '#060181',
          '#060181',
          '#060181',
          '#060181',
          ],
          data: this.dataOptions,
          showInLegend: false,
          dataLabels: {
            enabled: false,
          }
        }],
        credits: {
          enabled: false,
        }
      };

    },
  },
  watch:{
    cycle_config_id: {
      handler(){
        this.getLoginsPerWeek(`&cycle_config_id=${this.cycle_config_id}`)
    },
  },
},
  created(){
    if(!this.isLoadingCycleConfigs){
      this.getCycleConfigs(`&year=${(new Date).getFullYear()}`).then(() => {
        this.cycle_config_id = this.cycle_configs.find(c => c.is_current).id
      });
    }
  },
  methods: {
    ...mapActions(['getLoginsPerWeek','getCycleConfigs']),
    getY(week){
      return this.avg ? week.avg : week.count_logins
    },
    getZ(week){
      return week.count_active_students
    }
  },

}
  </script>

