import moment from 'moment'

export default {
  state: {
      list: [],
      current: null,
      isLoadingWeeks: false,
  },
  actions: {
      getWeeks({commit}, params='') {
          return axios.get(`/admin/week?${params}`)
          .then(r => {
              commit("SET_WEEKS", r.data.data.map(w=> {
                w.name = `Semana ${w.order} - Ciclo ${w.cycle_config.order + 1} (${w.cycle_config.year})`
                w.begin_formatted = moment(w.begin).format('DD/MM/YYYY hh:mm')
                w.end_formatted = moment(w.end).format('DD/MM/YYYY hh:mm')
                return w
              }));
              return r
          });
      },
      getWeek({commit}, params) {
          return axios.get(`/admin/week/${params.data.id}`).then(r => {
              commit("SET_WEEK", r.data.data);
          });
      },
      createWeek({commit}, params) {
          return axios.post(`/admin/week/`, params.data);
      },
      updateWeek({commit}, params) {
          return axios.put(`/admin/week/${params.id}`, params.data);
      },
      deleteWeek({commit}, params){
          return axios.delete(`/admin/week/${params.id}`);
      }
  },
  mutations: {
      SET_WEEKS(state, payload) {
          state.list = payload;
      },
      SET_WEEK(state, payload) {
          state.current = payload;
      }
  },
  getters: {
    weeks: state => state.list,
    week: state => state.current,
    isLoadingWeeks: state => state.isLoadingWeeks,
  }
};
