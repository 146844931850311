export default {
  state: {
    list: [],
    current: null,
    isLoadingGrades: false,
  },
  actions: {
    getGrades({commit, state}) {
        state.isLoadingGrades = true;
        return axios.get('/admin/grade').then(r => {
          commit("SET_GRADES", _.orderBy(r.data.data,[ 'order'],['asc']));
          commit("SET_GRADE",r.data.data[0]);
          return r;
        }).finally(() => state.isLoadingGrades = false);
    },
  },
  mutations: {
    SET_GRADES(state, payload) {
      state.list = payload;
    },
    SET_GRADE(state, payload) {
      state.current = payload;
    },
  },
  getters: {
    grades: state => state.list,
    grade: state => state.current,
    isLoadingGrades: state => state.isLoadingGrades,
  }

};
