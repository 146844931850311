<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          :can-create="false"
        />

        <Filters v-model="filters" />

        <List @changed="getStudents(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <BtnCycleConfigBlockQty
                :school="row"
                @changed="onChange"
              />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            :page-sizes="[10, 50, 100, 500]"
            layout="total, sizes, prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
            :page-size.sync="meta.per_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/school/components/List"
import Filters from "@/admin/Pages/school/components/Filters"
import { mapActions, mapGetters } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"
import BtnCycleConfigBlockQty from "@/admin/Pages/school/components/BtnCycleConfigBlockQty"

export default{
    components: {
        List,
        Filters,
        BtnCycleConfigBlockQty,
    },
    data() {
        return {
          filters : '',
          meta : {
              current_page: 1,
              total: 10,
              per_page: 10,
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','schools']),
      params(){
        return `${this.filters}&page=${this.meta.current_page}&per_page=${this.meta.per_page}&platformId=4`
      }
    },
    watch:{
      params: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
              this.onChange()
            }, 500)
        },
      },
    },
    methods:{
      ...mapActions(['getSchools']),
      onChange(){
        this.$loading(defaultLoadingConfig);
        this.getSchools(this.params)
            .then((r)=> {
                const { data :{meta} } = r
                this.meta.per_page = Number(meta.per_page);
                this.meta.current_page = meta.current_page;
                this.meta.total = meta.total;
        })
        .finally(()=>this.$loading().close())
      }
    },
}
</script>
