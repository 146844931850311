export default {
  title: [
    { required: true, message: 'O titulo é obrigatório.', trigger: 'blur' },
    { min: 5, max: 190, message: 'O titulo  deve ter entre 5 e 190 caracteres.', trigger: 'blur' }
  ],
  description: [
    { required: true, message: 'O campo abreviação é obrigatório.', trigger: 'blur' },
    { min: 5, max: 190, message: 'O campo abreviação deve ter entre 5 e 190 caracteres.', trigger: 'blur' }
  ],
  file: [
    { required: true, message: 'O arquivo é obrigatório.', trigger: 'blur' }
  ],
}
