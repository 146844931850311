<template>
  <div class="mx-4">
    <el-row v-if="module.contents">
      <el-col :md="12">
        <el-carousel

          :autoplay="false"
          trigger="click"
          arrow="always"
          height="600px"
        >
          <el-carousel-item
            v-for="(item, i) in module.contents"
            :key="i"
          >
            <el-container class="flex-column justify-content-center">
              <el-card>
                <InnerContent :content="item" />
              </el-card>
            </el-container>
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col :md="12">
        <ContentsModal :contents="module.contents" />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import ContentsModal from '@/admin/Pages/module/components/content/ContentsModal'
import InnerContent from '@/admin/Pages/module/components/content/InnerContent.vue'

export default {
  components: { InnerContent, ContentsModal },
  props:{
    module:{
      type: Object,
      required: true,
    }
  },
}
</script>
