<template>
  <div
    :style="`overflow-y: auto; overflow-x:hidden;max-height: ${defaultModalHeight}`"
    class="p-4 bg-white module-question-component"
  >
    <div
      v-for="(question, index) in questions"
      :key="index"
    >
      <div>
        <span class="text-warning">{{ index + 1 }})</span>
        {{ question.description }}
      </div>
      <div
        class="mt-2"
        v-html="question.content"
      >
        {{ question.content }}
      </div>
      <div
        v-for="(choice, key) in question.choices"
        :key="key"
        class="d-flex rounded my-2"
      >
        <div class="px-2 d-flex align-items-center">
          <div
            class="ml-2 choice-content-container"
            v-html="choice.content"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    questions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      defaultModalHeight: "60vh",
      alternatives:['a','b','c','d','e']
    }
  }
}
</script>
