export default {
    state: {
        list: [],
        current: {
            name: '',
            description: '',
            trail_id: '',
            themes: [],
        },
        isLoadingThemeBlocks: false,
    },
    actions: {
        getThemeBlocks({commit, state}, params = '') {
            state.isLoadingThemeBlocks = true;
            return axios.get(`/admin/theme-block?${params}`)
            .then(r => {
              const  {data: { data: theme_blocks }} = r
              commit("SET_THEME_BLOCKS", theme_blocks);
              return r
            })
            .finally(()=>state.isLoadingThemeBlocks = false);
        },
        getThemeBlock({commit}, params) {
            return axios.get(`/admin/theme-block/${params.data.id}`).then(r => {
                commit("SET_THEME_BLOCK", r.data.data);
            });
        },
        createThemeBlock({commit}, params) {
            return axios.post(`/admin/theme-block/`, params.data);
        },
        updateThemeBlock({commit}, params) {
            return axios.put(`/admin/theme-block/${params.id}`, params.data);
        },
        deleteThemeBlock({commit}, scorm){
            return axios.delete(`/admin/theme-block/${scorm.id}`);
        }
    },
    mutations: {
        SET_THEME_BLOCKS(state, payload) {
            state.list = payload;
        },
        SET_THEME_BLOCK(state, payload ) {
            if(payload)
                state.current = payload;
        }
    },
    getters: {
      theme_blocks: state => state.list,
      theme_block: state => state.current,
      isLoadingThemeBlocks: state => state.isLoadingThemeBlocks,
    }
};
