<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
        >
          <template #right>
            <el-button
              type="primary"
              :disabled="!filters"
              :loading="is_exporting"
              @click="exportData()"
            >
              Baixar relatório <i class="fa fa-download" />
            </el-button>
          </template>  
        </PageHeader>
        <FilterList v-model="filters"/>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import downloadFile from "@/utils/downloadFile.js"
import FilterList from "./FilterList.vue"

export default{
  components: {FilterList},
    data() {
        return {
          is_exporting: false,
          filters: '',
        }
    },
    methods:{
      exportData(){
        if(this.is_exporting){
          this.$message({ message: 'Em donwnload, aguarde.', type: 'warning', showClose: true})
        } else {
          this.is_exporting = true;
          downloadFile(`/admin/report/student-progress/export?${this.filters}`, this.$moment() + "-relatorio-progresso-sescola-ciclo.xlsx")
          .catch(() => this.$message({
            type: 'error',
            showClose: true,
            message: `Não foram encontrado registros para as escolas e séries selecionadas.Tente mudar o adicionar outras séries que tenham alunos.`
          }))
          .finally(() => this.is_exporting = false)
        }
      }
    },
}
</script>
