<template>
  <div class="mx--4">
    <el-skeleton
      v-if="isLoadingWarnings"
      :rows="10"
      :loading="isLoadingWarnings"
      animated
    />
    <el-table
      v-else
      :data="warnings"
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="p-4">
            <el-button-group>
              <!-- <el-button
                size="mini"
                type="info"
                @click="$loading();getWarningLogs(props.row).then(()=>$loading().close())"
              >
                Histórico de mudanças
              </el-button> -->
              <el-button
                v-if="permissions.includes('delete_warning')"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                :disabled="props.row.count_warnings > 0"
                @click="onDelete(props.row)"
              >
                Apagar
              </el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="Id"
        prop="id"
        width="56"
      />

      <el-table-column
        label="Título"
        prop="title"
      />

      <el-table-column 
        label="Ínicio" 
      >
        <template slot-scope="{ row }">
          {{ $moment(row.begin).format('DD-MM-YYYY HH:mm') }}
        </template>
      </el-table-column>

      <el-table-column 
        label="Fim" 
      >
        <template slot-scope="{ row }">
          {{ $moment(row.end).format('DD-MM-YYYY HH:mm') }}
        </template>
      </el-table-column>

      <el-table-column
        label="Perfil"
        prop="role_name"
        width="100"
      />
      <el-table-column
        fixed="right"
        label="Ações"
        width="100"
      >
        <template slot-scope="{ row }">
          <slot :row="row" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  computed:{
    ...mapGetters(['warnings','isLoadingWarnings', 'permissions',])
  },
  methods:{
    ...mapMutations(['SET_HISTORY','SET_WARNING_CONFIG']),
    ...mapActions(['deleteWarning','getWarningLogs']),
    onDelete({id}){
      this.$confirm('Tem certeza que deseja apagar o alerta ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() => this.deleteWarning({id}).then(()=>this.$emit('changed')))
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
  }
}
</script>

