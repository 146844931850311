<template>
  <div>
    <el-row class="mx-4">
      <el-col />
    </el-row>
    <el-table
      :data="challenge.questions"
      style="width: 100%"
    >
      <!-- <el-table-column type="expand">
        <template slot-scope="props">
          <div style="position:relative">
            <div v-html="props.row.content" />
          </div>
        </template>
      </el-table-column> -->

      <el-table-column
        label="ID"
        prop="id"
        width="120"
      />

      <el-table-column
        label="Descrição"
        prop="description"
      />
   
      <el-table-column
        label="Módulo"
        prop="module_name"
      >
        <template slot-scope="{ row } ">
          {{ `${ row.subject_acronym } - ${ row.module_name }` }}
        </template>
      </el-table-column>
      <el-table-column
        label="Resposta"
        prop="answer"
        width="100"
      />
      
      <el-table-column
        label="Respondido"
        prop="user_answer"
        width="300"
      >
        <template slot-scope="{row}">
          <EditQuestionAnswer :challenge-question="row" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import EditQuestionAnswer from '@/admin/Pages/challenge/components/EditQuestionAnswer'
export default {
  components: { EditQuestionAnswer },
  props: {
    challenge: {
      type: Object,
      required: true,
    }
  },
}
</script>
