<template>
  <el-row class="mx-5">
    <el-col>
      <el-card
        shadow="never"
        :body-style="{ padding: '0px' }"
      >
        <PageHeader
          slot="header"
          :can-create="false"
        />
        <el-alert
          title="Aviso"
          type="info"
          description="Ao apagar um usuário repetido será apagado seus registro de usuário e registros de módulos e desafio atribuídos para seu user_id, não impactando os usuário que será mantido."
          show-icon
        />
        <el-row
          :gutter="4"
          class="px-4 mt-4"
        >
          <el-col :md="4">
            <el-input
              v-model="search"
              placeholder="Pesquisar"
              clearable
            />
          </el-col>
        </el-row>

        <el-skeleton
          :rows="10"
          :loading="isLoadingRepeatedUsers && repeatedUsers.length == 0"
          animated
        >
          <el-table
            :data="filteredUsers"
            :row-style="tableRowStyle"
            style="width: 100%"
            height="65vh"
            row-key="Id"
          >
            <el-table-column
              sortable
              label="id"
              prop="id"
              width="100px"
            />
            <el-table-column
              sortable
              label="Userid"
              prop="userid"
              width="100px"
            />
            <el-table-column
              sortable
              label="Escola"
              prop="school_name"
              width="220px"
            />
            <el-table-column
              sortable
              label="Função"
              prop="role_name"
              width="100px"
            />
            <el-table-column
              sortable
              label="Nome"
              prop="user_name"
              width="300px"
            />
            <el-table-column
              sortable
              label="Logins"
              prop="count_logins"
              width="100px"
            />
            <el-table-column
              sortable
              label="Módulos"
              prop="count_selected_modules"
              width="120px"
            />
            <el-table-column
              sortable
              label="Desafios"
              prop="count_challenges"
              width="100px"
            />
            
            <el-table-column
              sortable
              label="Repetições"
              prop="count_repeat"
              width="120px"
            />
            <el-table-column
              sortable
              label="IdPlataforma"
              width="150px"
              prop="platformId"
            />
            <el-table-column
              sortable
              label="Data de criação"
              prop="created_at"
              width="150px"
            >
              <template slot-scope="{row}">
                {{ $moment(row.created_at).format('DD/MM/YY hh:mm') }}
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Ações"
              width="110"
            >
              <template slot-scope="{ row }">
                <el-button-group>
                  <el-button
                    size="mini"
                    type="danger"
                    @click="onDelete(row)"
                  >
                    Apagar
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-skeleton>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors";
import {  stringToColor } from '@/admin/utils/color';

export default {
  mixins:[RequestErrors],
    data() {
        return {
          search: '',
        }
    },
    computed:{
      ...mapGetters(['repeatedUsers','isLoadingRepeatedUsers']),
      filteredUsers(){
        return this.search.length ? 
          this.repeatedUsers
          .filter(u=> 
            u.user_name.toLowerCase().includes(this.search.toLowerCase()) || 
            u.school_name.toLowerCase().includes(this.search.toLowerCase()) ||
            u.role_name.toLowerCase().includes(this.search.toLowerCase())
          )
            :  this.repeatedUsers
      }
    },
    mounted(){
      this.getRepeatedUsers()
    },
    methods:{
        ...mapActions(['getRepeatedUsers','deleteRepeatedUser']),
        onDelete(user){
          this.$confirm('Tem certeza que deseja apagar o registro de usuário?', 'Atenção', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'warning',
          center: true
        }).then(() => {
          this.deleteRepeatedUser( { user_id : user.id , userid: user.userid })
          .then(()=>this.getRepeatedUsers())
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada'
          });
        });
      },
      tableRowStyle({row}) {
        return {
          backgroundColor: `${stringToColor(row.user_name)}`
        };
      }
    },
}
</script>
