export default {
    state: {
        list: [],
        current: null
    },
    actions: {
        getNetworks({commit}) {
            return axios.get("/admin/network").then(r => {
                commit("SET_NETWORKS", r.data.data);
            });
        },
        getNetwork({commit}, params) {
            return axios.get(`/admin/network/${params.data.id}`).then(r => {
                commit("SET_CURRENT_NETWORK", r.data.data);
            });
        },
        createNetwork({}, params) {
            return axios.post(`/admin/network/`, params.data);
        },
        updateNetwork({}, params) {
            return axios.put(`/admin/network/${params.id}`, params.data);
        },
        deleteNetwork({}, params){
            return axios.delete(`/admin/network/${params.id}`);
        }
    },
    mutations: {
        SET_NETWORKS(state, payload) {
            state.list = payload;
        },
        SET_CURRENT_NETWORK(state, payload) {
            state.current = payload;
        }
    },
};
