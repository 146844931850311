<template>
  <el-tooltip
    class="item"
    effect="dark"
    content="Apagar desafios"
    placement="top-center"
  >
    <el-button
      v-loading="isLoading"
      type="danger"
      icon="el-icon-delete"
      :disabled="disabled || !permissions.includes('delete-package')"
      @click="onClick"
    >
      {{ disabled ? 'Apagar todos os desafios para escola, série e ciclo selecionados' : `Apagar todos os desafios para a escola ${school.name} na ${grade.name} no ${cycle.name}`}}
    </el-button>
  </el-tooltip>
</template>
<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'

  export default {
  props:{
      grade: {
        type: Object,
        required: false,
        default: null,
      },
      school: {
        type: Object,
        required: false,
        default: null,
      },
      cycle: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data(){
      return {
        isLoading: false
      }
    },
    computed :{
      ...mapGetters(['challenges','permissions']),
      disabled(){
        return !this.cycle || !this.grade || !this.school 
        // || this.challenges.length == 0
      }
    },
    methods:{
      ...mapActions(['deleteProcessPackagesBySchoolCycleGrade']),
      ...mapMutations(['SET_CHALLENGES']),
      onClick(){
        this.$confirm(`Você solicitou a exclusão do pacotes do ${this.cycle.cycle_name} na ${this.grade.name} da escola ${this.school.name} 
          assim apagando os desafios dos alunos gerados nesses pacotes. 
          Você poderá gerar novamente os arquivos dos alunos impactados gerando novamente os desafios e arquivos.
          Essa solicitação não poderá ser desfeita.`, 
          'Warning', 
        {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(() => {
          this.onDelete()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada'
          });          
        });
      },
      onDelete(){
        this.isLoading = true
        this.$loading()
        axios.post(`/admin/maintenance/delete-process-packages`,{
          school_id: this.school.id,
          cycle_config_id: this.cycle.id,
          grade_id: this.grade.id,
        })
        .then(()=> {
          this.$message({
            message: 'Pacotes apagados com sucesso',
            type: 'success'
          });
          this.$emit('deleted');
        })
        .finally(()=>{
          this.$loading().close()
          this.isLoading = false
        })
      },
    },
    
  }

</script>
