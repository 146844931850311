<template>
  <el-row :gutter="20">
    <el-col>
      <div class="pb-2">
        {{ semester == 1 ? 'Ciclos 1 ao 4' : 'Ciclos 5 ao 8' }}
      </div>
    </el-col>
    <el-col :span="24">
      <el-select
        v-model="blocks_qty"
        v-loading="isLoadingCycles"
        element-loading-background="rgba(122, 122, 122, 0.5)"
        @change="e => handleChangeBlocksQty(e)"
      >
        <el-option
          v-for="(item, index) in [0,1,2,3]"
          :key="index"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-col>
  </el-row>
</template>
<script>
export default {
    props: {
        grade: {
            type: Object,
            required: true,
        },
        semester: {
            type: Number,
            required: true,
        },
        school: {
            type: Object,
            required: true,
        },
        value: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data(){
        return {
          blocks_qty: '',
          cycles: [],
          isLoadingCycles: false,
        }
  },
  computed: {
    filteredCycles() {
      return this.cycles ? this.cycles.filter(f => f.grade_id == this.grade.id) : []
    },
    quantityBlocks() {
      return this.cycles ? this.semester == 1 ? this.filteredCycles.find(f => f.order <= 4).blocks_qty
        : this.filteredCycles.find(f => f.order > 4).blocks_qty : []
    }
  },
  mounted() {
    if (!this.isLoadingCycles) {
      this.show()
    }
    },
  methods: {
        setConfigs(configs) {
          this.cycles = configs;
          this.blocks_qty = this.quantityBlocks
        },
        handleChangeBlocksQty(e) {
          this.blocks_qty = e
          this.onSubmit() 
        },
        show() {
        this.isLoadingCycles = true;
          axios.get(`/admin/school/cycle-grade-config/${this.school.id}`)
            .then(({ data: { data: configs } }) => {
              if (configs.length) {
                this.setConfigs(configs)
              }
            }).finally(() => this.isLoadingCycles = false);
        },
        onSubmit(){
            this.$loading()
            axios.post(`/admin/school/cycle-grade-config/`, {
                    school_id: this.school.id,
                    grade_id: this.grade.id,
                    semester: this.semester,
                    blocks_qty: this.blocks_qty,
            })
            .then(({data:{data: configs }}) => {
                this.$message({
                message: 'Alterações salvas com sucesso',
                type: 'success',
                })
                this.setConfigs(configs)
                this.$emit('input', this.blocks_qty)
            })
            .catch(() => {
                this.$message({
                message: 'Erro ao salvar alterações',
                type: 'error',
                })
            })
            .finally(()=> this.$loading().close())
        }
    }
}
</script>