<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />

        <el-dialog
          :title="ebook && ebook.id ? 'Editar Ebook' : 'Cadastrar Ebook'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getEbooks(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>

        <el-row :gutter="4">
          <el-col :md="6">
            <el-input
              v-model="search"
              placeholder="Pesquisar"
              clearable
            />
          </el-col>
        </el-row>

        <List @changed="getEbooks(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-ebook')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <a
                class="el-button el-button--info el-button--mini"
                :href="row.file.url"
                target="_blank"
              >Baixar</a>
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/ebook/components/List"
import Register from "@/admin/Pages/ebook/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','ebook','ebooks']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getEbooks(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getEbooks','getEbook']),
        ...mapMutations(['SET_EBOOK']),
        onEdit(ebook){
          this.registering = true;
          this.SET_EBOOK(ebook)
        },
        onCreate(){
            this.SET_EBOOK({
              name: '',
              description: '',
              file: null
            });
            this.registering = true;
        },
        onShow(ebook){
          this.SET_EBOOK(ebook)
          this.showing = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_EBOOK(null);
        },
    },
}
</script>
