<template>
  <el-form
    ref="form"
    :model="form"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="10">
      <el-col :md="4">
        <el-form-item label="Aluno">
          <el-input
            v-model="form.search"
            placeholder="Pesquisar por aluno"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :md="3">
        <el-form-item label="Escola">
          <el-select
            v-model="form.school_id"
            placeholder="Escola"
            clearable
            filterable
            class="w-100"
          >
            <el-option
              v-for="(loop_school, key) in schools"
              :key="key"
              :label="`${loop_school.name} (${loop_school.count_active_students} alunos)`"
              :value="loop_school.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="2">
        <el-form-item label="Ciclo">
          <el-select
            v-model="form.cycle_config_id"
            placeholder="Ciclo"
            clearable
            class="w-100"
          >
            <el-option
              v-for="(loop_cycle, key) in cycle_configs"
              :key="key"
              :label="`${loop_cycle.cycle_name} (${loop_cycle.year})`"
              :value="loop_cycle.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="2">
        <el-form-item label="Série">
          <el-select
            v-model="form.grade_id"
            placeholder="Série"
            clearable
          >
            <el-option
              v-for="(loop_grade, key) in grades"
              :key="key"
              :label="loop_grade.name"
              :value="loop_grade.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Status">
          <el-select
            v-model="status"
            placeholder="Status do desafio"
            clearable
            multiple
          >
            <el-option
              v-for="(status_challenge, key) in status_options"
              :key="key"
              :label="status_challenge.label"
              :value="status_challenge.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="5">
        <el-form-item label="Formato">
          <el-select
            v-model="form.challenge_type"
            placeholder="Formato do desafio"
            clearable
          >
            <el-option
              label="Impresso"
              value="print"
            />
            <el-option
              label="Online"
              value="online"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button
          icon="el-icon-search"
          type="primary"
          @click="$emit('input', filters)"
        >
          Filtrar
        </el-button> 
        <BtnDeleteBySchoolCycleGrade 
          :cycle="cycle"
          :school="school"
          :grade="grade"
          @deleted="$emit('deleted')"
        />
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import BtnDeleteBySchoolCycleGrade from './BtnDeleteBySchoolCycleGrade.vue'

export default {
  components:{BtnDeleteBySchoolCycleGrade},
  data() {
    return {
      form: {
        search: '',
        cycle_config_id: '',
        grade_id: '',
        school_id: '',
        challenge_type : 'print',
        finished: null
      },
      status: [],
      status_options: [
        {
          label: 'Finalizado',
          value: 'Finalizado',
        },
        {
          label: 'Iniciado e não finalizado',
          value: 'Iniciado e não finalizado',
        },
        {
          label: 'Não iniciado',
          value: 'Não iniciado',
        },
        {
          label: 'Em progresso',
          value: 'Em progresso',
        },
      ]
    }
  },
  computed: {
    ...mapGetters(['student','students','schools','cycle_configs','grades']),
    filters() {
      let filters = `&search=${this.form.search}&cycle_config_id=${this.form.cycle_config_id}&school_id=${this.form.school_id}&grade_id=${this.form.grade_id}`
      filters += ['print','online'].includes(this.form.challenge_type) ? `&challenge_type=${this.form.challenge_type}`:''
      filters += this.form.finished == 'finished' ? `&finished`:''
      filters += this.form.finished == 'unfinished' ? `&unfinished`:''
      this.status.forEach((s,key) => {
        filters += `&status[${key}]=${s}`
      })
      return filters;
    },
    cycle(){
      return this.cycle_configs.find(c=>c.id == this.form.cycle_config_id)
    },
    grade(){
      return this.grades.find(g=>g.id == this.form.grade_id)
    }, 
    school(){
      return this.schools.find(s=>s.id == this.form.school_id)
    }
  },
  mounted(){
    if(this.schools.length == 0 ){
      this.getSchools('order_by=name&order_direction=asc&per_page=1000');
    }
    if(this.grades.length == 0 ){
      this.getGrades()
    }
    this.getCycleConfigs()
  },
  methods:{
    ...mapActions(['getSchools','getCycleConfigs','getGrades']),
  },
}
</script>
