<template>
  <el-card
    shadow="never"
    :body-style="{ padding: '0px' }"
    class="mt-2"
  >
    <div slot="header">
      <el-row>
        <el-col
          :md="24"
          :lg="24"
        >
          <p class="font-weight-bold mb-0 font-weight-light text-sm">
            PROGRESSO NO CICLO POR ALUNO
          </p>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col
        :md="24"
        :lg="24"
      >
        <el-tabs
          v-model="tab"
        >
          <el-tab-pane
            label="TOTAL DE ALUNOS"
            name="TOTAL DE ALUNOS"
          >
            <span
              slot="label"
              class="pl-4"
            >TOTAL DE ALUNOS</span>
            <TotalStudentsProgress />
          </el-tab-pane>
          <el-tab-pane
            label="ALUNOS COM MÓDULOS"
            name="ALUNOS COM MÓDULOS"
          >
            <StudentsWithProgress />
          </el-tab-pane>
          <el-tab-pane
            label="ALUNOS COM MÓDULO SEM PROGRESSO"
            name="ALUNOS COM MÓDULO SEM PROGRESSO"
          >
            <StudentsWithNoProgress />
          </el-tab-pane>
          <el-tab-pane
            label="ALUNOS SEM MÓDULOS NO CICLO"
            name="ALUNOS SEM MÓDULOS NO CICLO"
          >
            <StudentsWithNoModules />
          </el-tab-pane>
          <el-tab-pane
            label="RESUMO"
            name="RESUMO"
          >
            <Resume />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import TotalStudentsProgress from './GlobalCycleProgress/TotalStudentsProgress.vue'
import StudentsWithProgress from './GlobalCycleProgress/StudentsWithProgress.vue'
import StudentsWithNoProgress from './GlobalCycleProgress/StudentsWithNoProgress.vue'
import StudentsWithNoModules from './GlobalCycleProgress/StudentsWithNoModules.vue'
import Resume from './GlobalCycleProgress/Resume.vue'
export default {
  components: {TotalStudentsProgress, StudentsWithNoProgress, StudentsWithProgress, StudentsWithNoModules, Resume},
  data(){
    return {
      tab: 'TOTAL DE ALUNOS',
    }
  },
  computed: {
    ...mapGetters(['global_cycle_progress_list','is_loading_global_cycle_progress']),
  },
  created(){
    if(this.global_cycle_progress_list.length == 0 && !this.is_loading_global_cycle_progress) {
      this.getGlobalCycleProgress()
    }
  },
    methods: {
    ...mapActions(['getGlobalCycleProgress']),
  },

  }

</script>
