export default {
  name: [
    { required: true, message: 'O nome é obrigatório.', trigger: 'blur' },
    { min: 5, max: 190, message: 'O nome deve ter entre 5 e 190 caracteres.', trigger: 'blur' }
  ],
  abbreviation: [
    { required: true, message: 'O campo abreviação é obrigatório.', trigger: 'blur' },
    { min: 5, max: 190, message: 'O campo abreviação deve ter entre 5 e 190 caracteres.', trigger: 'blur' }
  ],
  acronym: [
    { required: true, message: 'O campo acrônimo é obrigatório.', trigger: 'blur' },
    { min: 3, max: 190, message: 'O campo acrônimo deve ter entre 3 e 190 caracteres.', trigger: 'blur' }
  ],
  description: [
    { required: true, message: 'O campo descrição é obrigatório.', trigger: 'blur' },
    { min: 10, max: 1000, message: 'O campo descrição deve ter entre 10 e 1000 caracteres.', trigger: 'blur' }
  ],
  video: [
    { required: true, message: 'O link do vídeo é obrigatório.', trigger: 'blur' },
    { min: 10, max: 1000, message: 'O link do vídeo deve ter entre 10 e 1000 caracteres.', trigger: 'blur' }
  ],
  icon: [
    { required: true, message: 'Imagem icon é obrigatória.', trigger: 'blur' }
  ],
}
