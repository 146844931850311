export default {
    state: {
        list: [],
        is_loading_count_students_with_finished_challenge_in_current_and_next_cycle: false
    },
    actions: {
        getCountCountStudentsWithChallengeInCurrentAndNextCycle({commit, state}) {
            state.is_loading_count_schools_without_modules_on_cycle = true
            return axios.get(`/admin/report/count-students-with-finished-challenges-in-current-and-next-cycle`)
            .then(response => {1
              commit('SET_COUNT_STUDENT_WITH_FINISHED_CHALLENGES_IN_CURRENT_AND_NEXT_CYCLE', response.data.data)
              return response

            })
            .finally(()=> state.is_loading_count_schools_without_modules_on_cycle = true);
        },

    },
    mutations: {
        SET_COUNT_STUDENT_WITH_FINISHED_CHALLENGES_IN_CURRENT_AND_NEXT_CYCLE(state, payload) {
            state.list = payload;
        },
    },
    getters: {
      count_students_with_finished_challenge_in_current_and_next_cycle_list: state => state.list,
      is_loading_count_students_with_finished_challenge_in_current_and_next_cycle: state => state.is_loading_count_students_with_finished_challenge_in_current_and_next_cycle,
    }
};
