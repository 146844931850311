<template>
  <el-form
    ref="form"
    :model="form"
    :rules="FormRules"
    label-position="top"
    class="mx-4 mb-2"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Subtópico"
          prop="subtopic_id"
          :error="errors.subtopic_id"
        >
          <el-select
            v-model="form.subtopic_id"
            filterable
            class="w-100"
            placeholder="Selecione o subtópico do módulo"
            @change="onChangeSubtopic"
            @focus="!subtopics.length ? getSubtopics() : ''"
          >
            <el-option
              v-for="item in subtopics.filter(s=>s.id<1000)"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="name"
          :error="errors.name"
        >
          <el-input
            v-model="form.name"
            clearable
            name="name"
            show-word-limit
            maxlength="190"
            placeholder="Nome do módulo"
            :disabled="disableName"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Ebook"
          prop="ebook_id"
          :error="errors.ebook_id"
        >
          <el-select
            v-model="form.ebook_id"
            v-loading="isLoadingEbooks"
            filterable
            placeholder="Selecione o ebook do módulo"
            class="w-100"
            @focus="!ebooks.length ? getEbooks() : ''"
          >
            <el-option
              v-for="item in ebooks"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-form-item
          label="Disciplina"
          prop="subject_id"
          :error="errors.subject_id"
        >
          <el-select
            v-model="form.subject_id"
            filterable
            placeholder="Selecione a disciplina do módulo"
            class="w-100"
            @focus="!subjects.length ? getSubjects(): ''"
          >
            <el-option
              v-for="item in subjects"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <Contents
      v-model="form.contents"
      :contents="form.contents"
      @remove="removeContent"
    />
    <AddContents @add="addContent" />
    <p
      v-if="errors.contents"
      class="text-danger small"
    >
      {{ errors.contents }}
    </p>

    <el-col>
      <input
        id="checkbox"
        v-model="form.manageable"
        type="checkbox"
      >
      <label for="checkbox">Marcar como gerenciável</label>
    </el-col>

    <el-row class="mt-3">
      <el-col>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          Salvar
        </el-button>
        <el-button
          type="warning"
          @click="$emit('cancel')"
        >
          Cancelar
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapState } from 'vuex'
import FormRules from '@/admin/Pages/module/components/FormRulesModule'
import ModuleMixins from "@/admin/Pages/module/components/ModuleMixins.js";

export default {
  mixins:[ModuleMixins],
  data() {
    return {
      FormRules,
    }
  },
  computed: {
    ...mapState({
      subtopics: function(state) {
        return state.subtopic.list.filter(t => {
          return this.module ? this.module.id == t.module_id || !t.module_id : !t.module_id
        })
      },
    }),
    disableName() {
      return !this.form.subtopic_id
    }
  },
  mounted() {
    this.getSubtopics();
      this.getEbooks();
      this.getSubjects();
  },
  methods: {
    onChangeSubtopic() {
      if (!this.form.name) {
        this.form.name = this.subtopics.find(t => t.id === this.form.subtopic_id).name
      }
    },
    onSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.form.contents.forEach(function(array, i) {
            array.order = i
          })
          const form = JSON.parse(JSON.stringify(this.form))
          const action = this.module
            ? this.updateModule({
                data: form,
                id: this.module.id
              })
            : this.createModule({ data: this.form })
          action
            .then(() => {
              this.$message({
                message: 'Módulo salvo com sucesso.',
                type: 'success',
                showClose: true
              })
              this.$emit('onSave');
            })
            .catch(e => {
              this.showDefaultErrorMessages(e)
            })
        } else {
          return false
        }
      })
    },
  }
}
</script>
