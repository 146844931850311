<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          :can-create="false"
        />
        
        <FilterList
          v-model="search"
          @deleted="onDeleted"
          @restarted="onDeleted"
        />

        <List @changed="getProcessPackages(filters)">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-challenge')"
                icon="el-icon-edit"
                size="mini"
                type="primary"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
            </el-button-group>
          </template>
        </List>

        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            :page-sizes="[10, 30, 50, 100, 200, 500]"
            layout="sizes, prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
            :page-size.sync="meta.per_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/maintenance/process_packages/components/List"
import FilterList from "@/admin/Pages/maintenance/process_packages/components/FilterList"
import {mapActions, mapGetters} from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default {
  components: {
    List,
    FilterList
  },
  data() {
    return {
      search: '',
      meta: {
        current_page: 1,
        total: 10,
        per_page: 50,
      },
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    filters() {
      return `${this.search}&page=${this.meta.current_page}&per_page=${this.meta.per_page}`
    },
  },
  watch: {
    filters: {
      handler() {
        this.handleChange()
      },
    },
  },
  methods: {
    ...mapActions(['getProcessPackages','deleteProcessPackagesByIds']),
    handleChange(){
      clearTimeout(this._timerId)
        this._timerId = setTimeout(() => {
          this.$loading(defaultLoadingConfig);
          this.getProcessPackages(this.filters)
            .then((r) => {
              this.meta = {
                ...r.data.meta,
                per_page: Number(r.data.meta.per_page)
              };
            })
            .finally(() => this.$loading().close())
        }, 500)
      },
      onDeleted(){
          this.getProcessPackages(this.filters)
            .then((r) => {
              this.meta = {
                ...r.data.meta,
                per_page: Number(r.data.meta.per_page)
              };
              this.showSelectionModal = false;
            })
      }
  },
}
</script>