
const form = {
  type: 'theme',
  name: '',
  description: '',
  subject_id: '',
  trail_id: '',
  topic_id: '',
  thumbnail: '',
  contents: []
};
export default {
  state: {
    list: [],
    current: null,
    isLoadingThemes: false,
    form
  },
  actions: {
    getThemes({ commit, state }, params = '') {
      state.isLoadingThemes = true;
      return axios.get(`/admin/theme?${params}`)
      .then(r => {
        commit("SET_THEMES", r.data.data);
        return r;
      }).finally(() => state.isLoadingThemes = false);
    },
    getTheme({ commit }, {id}) {
      return axios.get(`/admin/theme/${id}`)
      .then(r => {
        const { data : {data : theme } }  = r
        commit("SET_THEME", theme);
        return r;
      });
    },
    createTheme({ commit }, params) {
      return axios.post(`/admin/theme/`, params.data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
    },
    updateTheme({ commit }, params) {
      return axios.post(`/admin/theme/${params.id}`, params.data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
    },
    deleteTheme({ commit }, params) {
      return axios.delete(`/admin/theme/${params.id}`);
    },
    getThemeLogs({commit},{id,name}){
      return axios.get(`/admin/theme/${id}/logs`)
      .then(r => {
        const {data: {data : logs }} = r
        commit('SET_HISTORY', {items: logs , title: `Histórico de Alterações do Item ${ name }`});
      })
    },
  },
  mutations: {
    SET_THEMES(state, payload) {
      state.list = payload;
    },
    SET_THEME(state, payload) {
      state.current = payload;
      state.form = payload ? {...payload, order: String(payload.order)} : form;
    },
  },
  getters:{
    themes: state => state.list,
    theme: state => state.current,
    isLoadingThemes: state => state.isLoadingThemes,
  }
};
