<template>
  <el-row class="mx-5">
    <el-col>
      <el-card
        shadow="never"
        :body-style="{ padding: '0px' }"
      >
        <div
          slot="header"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="font-weight-bold">
            Semanas
          </p>
          <el-button
            v-if="permissions.includes('create-week')"
            type="primary"
            @click.prevent="onCreate()"
          >
            <i class="fa fa-plus-circle" />
          </el-button>
        </div>

        <el-dialog
          :title="week && week.id ? 'Editar semana' : 'Cadastrar semana'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getWeeks(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>
        <el-row
          :gutter="4"
          class="px-4 mt-4"
        >
          <el-col :md="4">
            <el-select
              v-model="cycle_config_id"
              placeholder="Filtrar por ciclo"
              filterable
              clearable
            >
              <el-option
                v-for="(cc, index) in cycle_configs"
                :key="index"
                :label="`${cc.cycle_name} (${cc.year})`"
                :value="cc.id"
              />
            </el-select>
          </el-col>
        </el-row>

        <List @changed="getWeeks(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-week')"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>


import List from "@/admin/Pages/week/components/List"
import Register from "@/admin/Pages/week/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          cycle_config_id: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','week', 'cycle_configs']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}&cycle_config_id=${this.cycle_config_id}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getWeeks(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    mounted(){
      this.getCycleConfigs()
    },
    methods:{
        ...mapActions(['getWeeks','getCycleConfigs']),
        ...mapMutations(['SET_WEEK']),
        onEdit(page){
          this.registering = true;
          this.SET_WEEK(page)
        },
        onCreate(){
            this.SET_WEEK({
              begin: '',
              end: '',
              one_module_points: '',
              two_module_points: '',
              three_module_points: '',
              four_module_points: '',
              order: '',
              cycle_config_id: '',
            });
            this.registering = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_WEEK(null);
        },
    },
}
</script>
