<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingThemes"
      animated
    >
      <el-table
        :data="themes"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="p-4">
              <Detail :theme="props.row" />
              <el-button-group>
                <el-button
                  size="mini"
                  type="info"
                  @click="$loading(); getThemeLogs(props.row).then(()=>$loading().close())"
                >
                  Histórico de mudanças
                </el-button>
                <el-button
                  v-if="permissions.includes('delete-theme')"
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  :disabled="props.row.count_selections > 0"
                  @click="onDelete(props.row)"
                >
                  Apagar
                </el-button>
                <a
                  v-if="props.row.exam_file"
                  class="el-button el-button--primary el-button--mini"
                  size="mini"
                  type="danger"
                  :href="props.row.exam_file.url"
                  target="_blank"
                  download
                >
                  Baixar prova
                </a>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Id"
          prop="id"
          width="80"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          label="Conteúdos"
          width="100"
          prop="count_contents"
        />
        <el-table-column
          label="Área de interesse"
          prop="trail.name"
        />
        <el-table-column
          label="Bloco temático"
          prop="block.name"
        >
          <template slot-scope="{ row }">
            <router-link 
              v-if="row && row.block"
              :to="{ name: BlockIndexRoute.name, query:{ search: row.block ? row.block.name : '' } }"
            >
              {{ row.block.name }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          label="Ordem"
          prop="order"
          width="90"
        >
          <template slot-scope="{ row }">
            {{ row.order + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="Ações"
          width="320"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapGetters } from 'vuex';
import Detail from "@/admin/Pages/theme/components/Detail"
import { BlockIndexRoute } from '@/admin/routes/index.js'
export default {
  components: { Detail },
  data(){
    return {
      BlockIndexRoute,
    }
  },
  computed:{
    ...mapGetters(['permissions','themes', 'isLoadingThemes']),
  },
  methods:{
    ...mapMutations(['SET_HISTORY', 'SET_THEME']),
    ...mapActions(['deleteTheme', 'getThemeLogs',]),
    onDelete(theme){
      this.$confirm('Tem certeza que deseja apagar o módulo ?', 'Atenção', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
      .then(() => this.deleteTheme(theme)
      .then(()=>this.$emit('changed')))
      .catch(() => {
        this.$message({
            type: 'info',
            message: 'Ação cancelada!'
        });
      });
    },
  }
}
</script>

