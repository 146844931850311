<template>
  <el-card
    shadow="never"
    :body-style="{ padding: '0px' }"
    class="mt-2"
  >
    <div slot="header">
      <el-row>
        <el-col
          :md="15"
          :lg="15"
        >
          <p class="font-weight-bold mb-0 font-weight-light text-sm">
            MÉDIA DE LOGINS POR ALUNO COM MÓDULOS
          </p>
          <p class="mb-0 font-weight-light text-sm">
            Média de logins de alunos com módulos considerando o total de alunos que tem módulos de desempenho no ciclo
          </p>
        </el-col>
        <el-col
          :md="9"
          :lg="9"
        >
          <el-button-group>
            <el-button
              size="mini"
              :type="avg ? 'primary' : 'default'"
              @click="avg = true"
            >
              Média de acessos
            </el-button>
            <el-button
              size="mini"
              :type="!avg ? 'primary' : 'default'"
              @click="avg = false"
            >
              Total somado
            </el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <div class="p-3">
      <el-row
        v-for="cycle in logins_per_cycle"
        :key="cycle.id"
        :gutter="10"
      >
        <el-col :span="3">
          <el-tooltip
            :content="`${cycle.begin_formatted} - ${cycle.end_formatted}`"
            placement="top"
            effect="light"
          >
            <span class="pointer-on-hover font-weight-bold">{{ cycle.cycle_name }}</span>
          </el-tooltip>
        </el-col>
        <el-col
          :span="17"
          class="pt-1"
        >
          <el-progress
            :text-inside="false"
            :show-text="false"
            :stroke-width="16"
            :percentage="getPercentage(cycle.avg_logins_user_has_mod_on_cycle_per_user_has_mod_on_cycle)"
          />
        </el-col>
        <el-col :span="4">
          <p class="text-right">
            <span v-if="avg">
              <el-tooltip
                content="logins por aluno"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ Number(cycle.avg_logins_user_has_mod_on_cycle_per_user_has_mod_on_cycle).toFixed(2) }}</span>
              </el-tooltip>
            </span>
            <span v-else>
              <el-tooltip
                content="Logins de alunos com módulos no ciclo "
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ cycle.count_logins_for_students_with_modules }}</span>
              </el-tooltip> /
              <el-tooltip
                content="Alunos ativos com módulos no ciclo"
                placement="top"
                effect="light"
              >
                <span class="pointer-on-hover font-weight-bold">{{ cycle.count_active_students_has_modules_on_cycle }}</span>
              </el-tooltip>
            </span>
          </p>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>

import LoginsPerCycleMixins from './LoginsPerCycleMixins';

export default {
  mixins:[LoginsPerCycleMixins]
}
</script>
