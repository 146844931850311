<template>
  <el-form
    ref="form"
    :model="form"
    label-width="120px"
    label-position="top"
  >
    <el-row :gutter="4">
      <el-col :md="6">
        <el-form-item label="Nome">
          <el-input
            v-model="form.search"
            placeholder="Pesquisar"
            clearable
          />
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Disciplinas">
          <el-select
            v-model="form.subject_id"
            placeholder="Selecione a disciplina"
            filterable
            clearable
          >
            <el-option
              v-for="(s, index) in subjects"
              :key="index"
              :label="s.name"
              :value="s.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="4">
        <el-form-item label="Módulos">
          <el-select
            v-model="form.module_id"
            clearable
            placeholder="Selecione o módulo"
            filterable
          >
            <el-option
              v-for="(s, index) in modules.filter(m=> form.subject_id ? m.subject_id == form.subject_id : true)"
              :key="index"
              :label="s.name"
              :value="s.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      form: {
        search:'',
        subject_id: '',
        module_id: '',
      },
    }
  },
  computed:{
    ...mapGetters([
      'subjects',
      'modules',
    ]),
    filters(){
      return `search=${this.form.search}&subject_id=${this.form.subject_id}&module_id=${this.form.module_id}`
    }
  },
  watch:{
    filters: {
      handler(){
       this.$emit('input', this.filters)
      },
      immediate: true,
    }
  },
  mounted(){
    this.getSubjects();
    this.getModules();
  },
  methods:{
    ...mapActions(['getModules','getSubjects']),
  }
}

</script>

