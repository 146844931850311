<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingSubjects && all_subjects.length == 0"
      animated
    >
      <el-table
        :data="all_subjects"
        style="width: 100%"
      >
        <el-table-column
          label="Id"
          prop="id"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          label="Sigla"
          prop="acronym"
        />
        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  computed:{
    ...mapGetters(['permissions', 'all_subjects','isLoadingSubjects']),
  },
}
</script>

