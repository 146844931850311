<template>
  <div class="p-3">
    <el-tabs v-model="cycle_name">
      <el-tab-pane
        v-for="cc in global_cycle_progress_list"
        :key="cc.id"
        :label="cc.cycle_name"
        :name="cc.cycle_name"
      >
        <span
          slot="label"
          class="pl-4"
        > {{ cc.cycle_name }}
        </span>
        <el-row>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              suffix="%"
              :precision="2"
              :value="cc.avg_all_modules_progress"
              title="Média global de progresso dos módulos"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              suffix="%"
              :precision="2"
              :value="cc.avg_all_modules_progress_until_cycle_end"
              title="Média global de progresso dos módulos até o fim do ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="2"
              suffix="%"
              :value="cc.avg_progress"
              title="Média de progresso no ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="2"
              suffix="%"
              :value="cc.avg_progress_until_cycle_end"
              title="Média de progresso no ciclo até o fim do ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="0"
              :value="cc.count_active_students"
              title="Alunos ativos"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="0"
              :value="Number(cc.count_students_has_performance_modules_on_cycle)"
              title="Alunos ativos com módulos no ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="0"
              :value="Number(cc.count_students_has_performance_modules_on_cycle_with_no_progress)"
              title="Alunos ativos com módulos sem progresso no ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="0"
              :value="Number(cc.count_students_has_not_performance_modules_on_cycle)"
              title="Alunos ativos sem módulos de desempenho no ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="0"
              :value="Number(cc.count_challenges)"
              title="Desafios no ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="0"
              :value="Number(cc.count_finished_challenges)"
              title="Desafios finalizados no ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="2"
              suffix="%"
              :value="Number(cc.finished_challenges_percentage)"
              title="Porcentagem de desafios finalizados"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="2"
              suffix="%"
              :value="Number(cc.finished_challenges_until_cycle_end_percentage)"
              title="Porcentagem de desafios finalizados até o fim do ciclo"
            />
          </el-col>
          <el-col
            class="p-2"
            :md="8"
            :lg="8"
          >
            <el-statistic
              group-separator="."
              :precision="2"
              suffix="%"
              :value="Number(cc.finished_challenges_finished_until_cycle_end_percentage)"
              title="Porcentagem de desafios finalizados até o fim do ciclo entre os desafios finalizados"
            />
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  data(){
    return {
      cycle_name: 'Ciclo 1' ,
    }
  },
  computed: {
    ...mapGetters(['global_cycle_progress_list']),
  },
}
</script>
