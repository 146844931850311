<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader
          slot="header"
        />

        <el-dialog
          :title="subject && subject.id ? 'Editar  disciplina' : 'Editar  disciplina'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getSubjects(); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>
        <List @changed="getSubjects(); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="true"
                size="mini"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
            </el-button-group>
          </template>
        </List>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/subject/components/List"
import Register from "@/admin/Pages/subject/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default{
  components: {
    List,
    Register,
  },
    data() {
        return {
          registering: false,
        }
    },
    computed:{
      ...mapGetters(['permissions','subject']),
    },
    mounted() {
      this.getSubjects();
    },
    methods:{
      ...mapActions(['getSubjects']),
        ...mapMutations(['SET_SUBJECT']),
        onEdit(page){
          this.registering = true;
          this.SET_SUBJECT(page)
        },
        onCancel(){
            this.registering = false;
            this.SET_SUBJECT(null);
        },
    },
}
</script>
