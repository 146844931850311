export default {
    state: {
        list: [],
        current: null,
        isLoadingRoles: false,
    },
    actions: {
        getRoles({commit, state}, params = '') {
            state.isLoadingRoles = true;
            return axios.get(`/admin/role?${params}`)
            .then(r => {
                commit("SET_ROLES", r.data.data);
                return r;
            })
            .finally(() => state.isLoadingRoles = false);
        },
        getRole({commit}, params) {
            return axios.get(`/admin/role/${params.data.id}`,).then(r => {
                commit("SET_ROLE", r.data.data);
            });
        },
        createRole({commit}, params) {
            return axios.post(`/admin/role/`, params.data);
        },
        updateRole({commit}, params) {
            return axios.put(`/admin/role/${params.id}`, params.data);
        },
        deleteRole({commit}, params) {
            return axios.delete(`/admin/role/${params.id}`);
        }
    },
    mutations: {
        SET_ROLES(state, payload) {
            state.list = payload;
        },
        SET_ROLE(state, payload) {
            state.current = payload;
        }
    },
    getters: {
        roles : state => state.list,
        role : state => state.current,
        isLoadingRoles : state => state.isLoadingRoles,
    }

};
