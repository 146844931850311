import Router from "vue-router";
import DefaultLayout from "@/admin/layouts/DefaultLayout";

import LoginPage from "@/admin/Pages/login/Index";
export const LoginRoute = {
    path: "/admin/login",
    component: LoginPage,
    name: 'login',
    meta: {
        label: 'Login',
    }
}

import NotFoundPage from "@/admin/Pages/PageNotFound";
export const NotFoundRoute = {
    path: "*",
    alias: '*',
    component: NotFoundPage,
    meta: {
        label: 'Página não encontrada',
    }
}

import CycleConfigPage from "@/admin/Pages/cycle_config/Index";
export const CycleConfigIndexRoute = {
    path: "/administrativo/cycle-config",
    component: CycleConfigPage,
    name: 'view-cycle',
    meta: {
     label: 'Ciclos',
     iconClass: 'fas fa-network-wired'
    }
}

import ChallengeApplicationPeriod from "@/admin/Pages/challenge_application_period/Index";
export const ChallengeApplicationPeriodRoute = {
    path: "/administrativo/janela-aplicacao",
    component: ChallengeApplicationPeriod,
    name: 'edit-application-period',
    meta: {
     label: 'Janela de aplicacão',
     iconClass: 'fas fa-network-wired'
    }
}

import DashboardPage from "@/admin/Pages/dashboard/Index";
export const DashboardIndexRoute = {
    path: "/",
    component: DashboardPage,
    name: 'view-dashboard',
    meta: {
     label: 'Dashboard',
     iconClass: 'fas fa-chart-bar',
     allow_all: true,
    }
}


import PermissionPage from "@/admin/Pages/permission/Index";
export const PermissionIndexRoute = {
    path: "/administrativo/permission",
    component: PermissionPage,
    name: 'view-permission',
    meta: {
     label: 'Permissões',
     iconClass: 'fas fa-chart-bar',
    }
}



import RolePage from "@/admin/Pages/role/Index";
export const RoleIndexRoute = {
    path: "/administrativo/role",
    component: RolePage,
    name: 'view-role',
    meta: {
     label: 'Perfis',
     iconClass: 'fas fa-chart-bar'
    }
}

import SchoolPage from "@/admin/Pages/school/Index";
export const SchoolIndexRoute = {
    path: "/administrativo/school",
    component: SchoolPage,
    name: 'view-school',
    meta: {
     label: 'Escolas',
     iconClass: 'fas fa-school'
    }
}

import StudentPage from "@/admin/Pages/student/Index";
export const StudentIndexRoute = {
    path: "/administrativo/student",
    component: StudentPage,
    name: 'view-student',
    meta: {
     label: 'Alunos',
     iconClass: 'fas fa-chart-bar'
    }
}

import ModulePage from "@/admin/Pages/module/Index";
export const ModuleIndexRoute = {
    path: "/administrativo/module",
    component: ModulePage,
    name: 'view-module',
    meta: {
     label: 'Módulos de desempenho',
     iconClass: 'fas fa-th-large'
    }
}

// Página de cadastro de módulos do ciclo 0 (Pré-ciclo)
import PreCycleModulePage from "@/admin/Pages/pre_cycle_module/Index";
export const PreCycleModuleRoute = {
    path: "/administrativo/pre-cycle-module",
    component: PreCycleModulePage,
    name: 'view-pre-cycle-module',
    meta: {
     label: 'Módulos pré-ciclo',
     iconClass: 'fas fa-th-large'
    }
}

import ThemePage from "@/admin/Pages/theme/Index";
export const ThemeIndexRoute = {
    path: "/administrativo/theme",
    component: ThemePage,
    name: 'view-theme',
    meta: {
     label: 'Módulos de carreira',
     iconClass: 'fas fa-swatchbook'
    }
}

import BlockPage from "@/admin/Pages/theme_block/Index";
export const BlockIndexRoute = {
    path: "/administrativo/block",
    component: BlockPage,
    name: 'view-block',
    meta: {
     label: 'Blocos temáticos',
     iconClass: 'fas fa-swatchbook'
    }
}

import TicketPage from "@/admin/Pages/ticket/Index";
export const TicketIndexRoute = {
    path: "/administrativo/ticket",
    component: TicketPage,
    name: 'view-ticket',
    meta: {
     label: 'Tickets',
     iconClass: 'fas fa-chart-bar'
    }
}

import TrailPage from "@/admin/Pages/trail/Index";
export const TrailIndexRoute = {
    path: "/administrativo/trail",
    component: TrailPage,
    name: 'view-trail',
    meta: {
     label: 'Áreas de interesse',
     iconClass: 'fas fa-map-marked-alt'
    }
}

import UserPage from "@/admin/Pages/user/Index";
export const UserIndexRoute = {
    path: "/administrativo/user",
    component: UserPage,
    name: 'view-user',
    meta: {
     label: 'Administradores',
     iconClass: 'fas fa-users'
    }
}

import WarningPage from "@/admin/Pages/warning/Index";
export const WarningIndexRoute = {
    path: "/administrativo/warning",
    component: WarningPage,
    name: 'view-warning',
    meta: {
     label: 'Alertas',
     iconClass: 'fas fa-bell'
    }
}

import WeekPage from "@/admin/Pages/week/Index";
export const WeekIndexRoute = {
    path: "/administrativo/week",
    component: WeekPage,
    name: 'view-week',
    meta: {
     label: 'Semanas',
     iconClass: 'fas fa-network-wired'
    }
}

import SchoolCycleReportPage from "@/admin/Pages/reports/school-cycle/Index";
export const SchoolCycleReportIndexRoute = {
    path: "/administrativo/report/school-cycle-report",
    component: SchoolCycleReportPage,
    name: 'school-cycle-report',
    meta: {
     label: 'Relatório Escolas por ciclo',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

import StudentGlobalReportPage from "@/admin/Pages/reports/student-global/Index";
export const StudentGlobalReportRoute = {
    path: "/administrativo/report/student-global-report",
    component: StudentGlobalReportPage,
    name: 'student-global-report',
    meta: {
     label: 'Relatório Geral',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

import StudentsProgressReportPage from "@/admin/Pages/reports/students-progress/Index";
export const StudentsProgressReportRoute = {
    path: "/administrativo/report/student-progress",
    component: StudentsProgressReportPage,
    name: 'students-progress',
    meta: {
     label: 'Relatório Progresso',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}

import StudentPerTrailReportPage from "@/admin/Pages/reports/students-per-trail/Index";
export const StudentsPerTrailReportRoute = {
    path: "/administrativo/report/students-per-trail",
    component: StudentPerTrailReportPage,
    name: 'students-per-trail',
    meta: {
     label: 'Relatório Áreas de interesse',
     iconClass: 'fa-chart-pie fas',
     category: 'report',
    }
}
import maintenanceRoutes from "./maintenance.js";
import contentRoutes from "./content.js";

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: 'administrativo'
        },
      {
        path: "/administrativo",
        component: DefaultLayout,
        children: [
            DashboardIndexRoute,
            TrailIndexRoute,
            BlockIndexRoute,
            ModuleIndexRoute,
            PreCycleModuleRoute,
            ThemeIndexRoute,
            SchoolIndexRoute,
            StudentIndexRoute,
            TicketIndexRoute,
            WarningIndexRoute,
            CycleConfigIndexRoute,
            ChallengeApplicationPeriodRoute,
            WeekIndexRoute,
            UserIndexRoute,
            RoleIndexRoute,
            PermissionIndexRoute,
            SchoolCycleReportIndexRoute,
            StudentGlobalReportRoute,
            StudentsPerTrailReportRoute,
            StudentsProgressReportRoute,
            ...contentRoutes,
            ...maintenanceRoutes,

        ]
    },
    LoginRoute,
    NotFoundRoute,
    ],
});

// router.beforeEach((to, from, next) => {

//     /*AO ENTRAR NA ROTA SE O USUÁRIO NÃO ESTIVER SETADO TENTA PEGAR O USUÁRIO LOGADO*/
//     if (!Store.state.auth.user) {
//         Store.dispatch('isLoading');
//         startSessionRequests()
//             .then(() => {
//                 next();
//             })
//             .finally(() => Store.dispatch( 'isLoading', false ));
//     } else {
//         next();
//     }
// });

export default router;
