<template>
  <div>
    <p>
      <span class="font-weight-bold">Nome:</span> {{ theme.name }}
    </p>
    <p>
      <span class="font-weight-bold">Descrição:</span> {{ theme.description }}
    </p>
    <p>
      <span class="font-weight-bold">Disciplina:</span> {{ theme.subject.name }}
    </p>
    <p>
      <span class="font-weight-bold">Área de interesse:</span> {{ theme.trail.name }}
    </p>
    <p v-if="theme.block">
      <span class="font-weight-bold">Bloco temático:</span> {{ theme.block.name }}
    </p>
    <p>
      <span class="font-weight-bold">Ciclo:</span> {{ theme.order }}
    </p>
    <p>
      <span class="font-weight-bold">Quantidade de conteúdos:</span> {{ theme.count_contents }}
    </p>
    <p>
      <span class="font-weight-bold">Disponível em:</span> {{ $moment(theme.available_at).format('D/M/y hh:mm') }}
    </p>
  </div>
</template>
<script>
  export default {
    props:{
      theme: {
        type: Object,
        required: true,
      }
    }
  }
</script>

