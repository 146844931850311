export const firstWord = (string, param = "' '") => param ? string.split(param)[0] : string.split(' ')[0];

import {Loading} from 'element-ui'
export const redirect = (location, another_page, loading= true) => {
  if(loading) {
    Loading.service({
      fullscreen:true,
    })
  }
  another_page ? window.open(location, '_blank') : window.location = location
};
