<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />

        <el-dialog
          :title="`${ scorm ? scorm.title : ''}`"
          width="50%"
          class="with-border"
          :visible.sync="showing"
          center
          @closed="SET_SCORM(null)"
        >
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              v-if="scorm"
              :src="scorm.url"
              :title="scorm.title"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </el-dialog>
        <el-dialog
          :title="scorm && scorm.id ? 'Editar Scorm' : 'Nova Scorm'"
          :visible.sync="registering"
          fullscreen
          center
        >
          <Register
            v-if="registering"
            @onSave="getScorms(filters); onCancel()"
            @cancel="onCancel()"
          />
        </el-dialog>

        <el-row :gutter="4">
          <el-col :md="6">
            <el-input
              v-model="search"
              placeholder="Pesquisar"
              clearable
            />
          </el-col>
          <el-col :md="10">
            <el-radio-group
              v-model="used"
              class="mt-2"
            >
              <el-radio label="true">
                Utilizado
              </el-radio>
              <el-radio label="false">
                Não utilizado
              </el-radio>
              <el-radio label="">
                Todos
              </el-radio>
            </el-radio-group>
          </el-col>
        </el-row>

        <List @changed="getScorms(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-scorm')"
                size="mini"
                type="warning"
                icon="el-icon-edit"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                v-if="permissions.includes('delete-scorm')"
                size="mini"
                icon="el-icon-delete"
                type="danger"
                @click="onDelete(row)"
              >
                Apagar
              </el-button>
              <el-button
                size="mini"
                icon="el-icon-eye"
                @click="onShow(row)"
              >
                Ver
              </el-button>
              <a
                class="el-button el-button--info el-button--mini"
                :href="row.url"
                target="_blank"
              >Baixar</a>
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/scorm/components/List"
import Register from "@/admin/Pages/scorm/components/Register"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default{
    components: {
        List,
        Register,
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          used: null,
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','scorm','scorms']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}${this.used?`&used=${this.used}`:''}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
                this.getScorms(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                })
                .finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getScorms', 'deleteScorm']),
        ...mapMutations(['SET_SCORM','SET_HISTORY']),
        onEdit(scorm){
          this.registering = true;
          this.SET_SCORM(scorm)
        },
        onCreate(){
            this.SET_SCORM({
              name: '',
              description: '',
              file: null
            });
            this.registering = true;
        },
        onShow(scorm){
          this.SET_SCORM(scorm)
          this.showing = true;
        },
        onCancel(){
            this.registering = false;
            this.SET_SCORM(null);
        },
        onDelete(scorm){
          this.$confirm('Tem certeza que deseja apagar o scorm ?', 'Atenção', {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            type: 'warning'
          })
          .then(() => this.deleteScorm(scorm))
          .then(()=>this.getScorms(this.filters))
          .catch(() => {
            this.$message({
                type: 'info',
                message: 'Ação cancelada!'
            });
          });
        },
    },
}
</script>
