<template>
  <div class="mx-4">
    <div class="d-flex justify-content-center">
      <div class="bg-image bg-white">
        <div
          class="
            bg-content
            d-flex
            flex-column
            justify-content-center
            text-center
            h-100
          "
        >
          <div>
            <div class="row">
              <div>
                <h1 class="text-orange font-weight-bold">
                  Links externos
                </h1>
                <div v-for="(link, l) in link" :key="l">
                  <p class="font-weight-bold mb-0">
                    {{ link.title }}
                  </p>
                  <p class="small font-italic m-0">
                    {{ link.description }}
                  </p>
                  <a
                    class="btn btn-orange text-light font-weight-bold"
                    role="button"
                    :href="link.url"
                    target="_blank"
                  >
                    Acessar
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    link: {
      type: Array,
      required: true
    }
  }
}
</script>
