<template>
  <el-button
    v-loading="isLoading"
    type="danger"
    icon="el-icon-delete"
    :disabled="disabled || !permissions.includes('delete-package')"
    @click="onClick"
  >
    {{ disabled ? 'Reiniciar todos os pacotes para escola e série selecionados' : `Reiniciar todos os pacotes para a escola ${school.name} na ${grade.name} ` }}
  </el-button>
</template>
<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex'

  export default {
  props:{
      grade: {
        type: Object,
        required: false,
        default: null,
      },
      school: {
        type: Object,
        required: false,
        default: null,
      },
      cycle: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data(){
      return {
        isLoading: false
      }
    },
    computed :{
      ...mapGetters(['challenges','permissions']),
      disabled(){
        return !this.grade || !this.school 
      }
    },
    methods:{
      ...mapActions(['deleteProcessPackagesBySchoolCycleGrade']),
      ...mapMutations(['SET_CHALLENGES']),
      onClick(){
        this.$confirm(`Reiniciar todos os pacotes na ${this.grade.name} da escola ${this.school.name}. 
          Todos os pacotes terão seu status alterado para terem seus arquivos gerados novamente.
          Essa ação não impacta os módulos escolhidos.`, 
          'Warning', 
        {
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          type: 'warning'
        }).then(() => {
          this.onDelete()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Ação cancelada'
          });          
        });
      },
      onDelete(){
        this.isLoading = true
        this.$loading()
        axios.post(`/admin/maintenance/restart-process-packages`,{
          school_id: this.school.id,
          grade_id: this.grade.id,
        })
        .then(()=> {
          this.$message({
            message: 'Pacotes reiniciados com sucesso',
            type: 'success'
          });
          this.$emit('restarted');
        })
        .finally(()=>{
          this.$loading().close()
          this.isLoading = false
        })
      },
    },
    
  }

</script>
