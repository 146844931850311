<template>
  <div>
    <h2>Enunciado:</h2>
    <div
      class="question-content"
      v-html="question.content"
    />
    <AnswerList :dinamic="false" />
  </div>
</template>
<script>

import AnswerList from "@/admin/Pages/question/components/AnswerList.vue";
import { mapGetters } from 'vuex';

export default {
  components:{
    AnswerList,
  },
  computed:{
    ...mapGetters(['question']),
  }
}
</script>
<style lang="scss">
.question-content{
  img{
    max-width: 100%;
    width: 100%;
  }
}
</style>
