<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingPermissions && permission_list.length == 0"
      animated
    >
      <el-table
        :data="permission_list"
        style="width: 100%"
      >
        <el-table-column
          label="Id"
          prop="id"
          width="50"
        />
        <el-table-column
          label="Exibição"
          prop="label"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          label="Descrição"
          prop="description"
        />
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import {  mapGetters } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  computed:{
    ...mapGetters(['permission_list','isLoadingPermissions']),
  },
}
</script>

