export default {
    state: {
        list: [],
        current: null,
        isLoadingFiles:false,
    },
    actions: {
        getFiles({commit,state}, params = '') {
            state.isLoadingFiles = true;
            return axios.get(`/admin/file?${params}`)
            .then(r => {
                commit("SET_FILES", r.data.data);
                return r;
            })
              .finally(()=>state.isLoadingFiles = false);
        },
        getFile({commit}, params) {
            return axios.get(`/admin/file/${params.data.id}`)
            .then(r => {
                commit("SET_FILE", r.data.data);
                return r
            });
        },
        createFile({commit}, { data }) {
            return axios.post(`/admin/file/`, data, {
              headers: {
                "Content-Type": `multipart/form-data`
              }
            });
        },
        updateFile({commit}, params) {
            return axios.post(`/admin/file/${params.id}`, params.data,{
              headers: {
                "Content-Type": `multipart/form-data`
              }
            });
        },
        deleteFile({commit}, params) {
            return axios.delete(`/admin/file/${params.id}`,{
              headers: {
                "Content-Type": `multipart/form-data`
              }
            });
      },
    },
    mutations: {
        SET_FILES(state, payload) {
            state.list = payload;
        },
        SET_FILE(state, payload) {
            state.current = payload;
        }
    },
    getters:{
        files: state => state.list,
        file: state => state.current,
        isLoadingFiles: state => state.isLoadingFiles,
        active_files: state => state.list.filter(t => t.active),
    }

};
