<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingFiles && filteredFiles.length == 0"
      animated
    >
      <el-table
        :data="filteredFiles"
        style="width: 100%"
      >
        <el-table-column
          label="Id"
          prop="id"
          width="50"
        />
        <el-table-column
          label="Título"
          prop="title"
        />
        <el-table-column
          label="Descrição"
          prop="description"
        />
        <el-table-column
          label="Arquivo"
          prop="file"
          width="100"
        >
          <template slot-scope="{ row }">
            <a
              :href="row.file.url"
              target="_blank"
            >Download</a>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  props: {
    deletedFileId: {
      type: Number,
      required: true,
    },
  },
  computed:{
    ...mapGetters(['files', 'isLoadingFiles']),
      filteredFiles() {
      return this.files.filter((file) => file.id !== this.deletedFileId)
    },
  },
  watch: {
    deletedFileId: {
      handler() {
        this.$store.commit('SET_FILES', this.filteredFiles)
      },
      deep: true,
    },
  },
}
</script>

