<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          :can-create="false"
        />
        
        <FilterList v-model="search" />

        <List>
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-challenge')"
                icon="el-icon-edit"
                size="mini"
                type="primary"
              >
                Editar
              </el-button>
            </el-button-group>
          </template>
        </List>

        <div class="d-flex justify-content-center p-2">
          <el-pagination
            :current-page.sync="meta.current_page"
            :total="meta.total"
            background
            layout="prev, pager, next"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/challenge/components/List"
import FilterList from "@/admin/Pages/challenge/components/FilterList"
import {mapActions, mapGetters} from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"

export default {
  components: {
    List,
    FilterList
  },
  data() {
    return {
      registering: false,
      search: '',
      meta: {
        current_page: 1,
        total: 10
      },
    }
  },
  computed: {
    ...mapGetters(['permissions']),
    filters() {
      return `${this.search}&page=${this.meta.current_page}`
    },
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this._timerId)
        this._timerId = setTimeout(() => {
          this.$loading(defaultLoadingConfig);
          this.getChallenges(this.filters)
            .then((r) => {
              this.meta = r.data.meta;
            })
            .finally(() => this.$loading().close())
        }, 500)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['getChallenges','updateChallenge',]),
    handleChange(){
      clearTimeout(this._timerId)
        this._timerId = setTimeout(() => {
          this.$loading(defaultLoadingConfig);
          this.getChallenges(this.filters)
            .then((r) => {
              this.meta = r.data.meta;
            })
            .finally(() => this.$loading().close())
        }, 500)
    }
  },
}
</script>