import Vue from 'vue'
// Highcharts 1st
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

import variablePieInit from 'highcharts/modules/variable-pie'
import solidGauge from 'highcharts/modules/solid-gauge';

variablePieInit(Highcharts)
solidGauge(Highcharts)

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue)