export default {
    state: {
        list: [],
        current: null,
        isLoadingQuestions: false,
    },
    actions: {
        getQuestions({commit, state}, params) {
          state.isLoadingQuestions = true;
            return axios.get(`/admin/question?${params}`).then(response => {
              const  {data: { data: questions }} = response
                commit("SET_QUESTIONS", questions);
                return response
            })
            .finally(()=>state.isLoadingQuestions = false);
        },
        getQuestion({commit}, params) {
            return axios.get(`/admin/question/${params.data.id}`)
            .then(r => {
                commit("SET_QUESTION", r.data.data);
            });
        },
        createQuestion({commit}, params) {
            return axios.post(`/admin/question`, params.data);
        },
        updateQuestion({commit}, params) {
            return axios.put(`/admin/question/${params.id}`, params.data);
        },
        deleteQuestion({commit}, params){
            return axios.delete(`/admin/question/${params.id}`);
        }
    },
    mutations: {
        SET_QUESTION(state, payload) {
            state.current = payload;
        },
        SET_QUESTIONS(state, payload) {
            state.list = payload;
        }
    },
    getters: {
      questions: state =>  state.list,
      question: state =>  state.current,
    }
};
