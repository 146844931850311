export default {
    title: [
      { required: true, message: 'O título do alerta é obrigatório.', trigger: 'blur' },
      { min: 5, max: 100, message: 'O título deve ter entre 5 e 100 caracteres.', trigger: 'blur' }
    ],
    content: [
      { required: true, message: 'O conteúdo do alerta é obrigatório.', trigger: 'blur' },
    ],
    role_id: [
        { type: 'number', required: true, message: 'O campo tipo de usuário é obrigatório.', trigger: 'blur' },
    ],
    begin: [
        { required: true, message: 'A data de início do alerta é obrigatória.' }
    ],
    end: [
        { required: true, message: 'A data de fim do alerta é obrigatória.' }
    ],
    redirect_link: [
        { type: 'string', min: 10, max: 190, message: 'A url deve ter entre 10 e 190 caracteres.', trigger: 'blur' }
    ],
    button_link_name: [
        { type: 'string', min: 5, max: 20, message: 'A url deve ter entre 5 e 20 caracteres.', trigger: 'blur' },
    ]
  }
  