
// Rotas manutencao
import StudentModuleOrderPage from "@/admin/Pages/maintenance/student_module_order/Index";
export const StudentModuleOrderRoute = {
    path: "/manutencao/ordenar-modulos-alunos",
    component: StudentModuleOrderPage,
    name: 'maintenance.student.module.order',
    meta: {
     label: 'Ordenação de módulos',
     iconClass: 'fa-chart-pie fas',
     category: 'maintenance',
    }
}

import DeleteChallengesPage from "@/admin/Pages/maintenance/challenge/Index";
export const DeleteChallengesRoute = {
    path: "/manutencao/apagar-desafios",
    component: DeleteChallengesPage,
    name: 'maintenance.delete.challenges',
    meta: {
     label: 'Apagar desafios',
     iconClass: 'fa-chart-pie fas',
     category: 'maintenance',
    }
}

import RepeatedUsersPage from "@/admin/Pages/maintenance/repeated_users/Index";
export const RepeatedUsersRoute = {
    path: "/manutencao/usuarios-repetidos",
    component: RepeatedUsersPage,
    name: 'maintenance.repeated.users',
    meta: {
     label: 'Usuários repetidos',
     iconClass: 'fa-chart-pie fas',
     category: 'maintenance',
    }
}

import DeleteProcessPackagesPage from "@/admin/Pages/maintenance/process_packages/Index";
export const DeleteProcessPackagesRoute = {
    path: "/manutencao/apagar-pacotes",
    component: DeleteProcessPackagesPage,
    name: 'view-package',
    meta: {
     label: 'Gerenciar pacotes',
     iconClass: 'fa-chart-pie fas',
     category: 'maintenance',
    }
}

import SetupModulesPage from "@/admin/Pages/maintenance/setup_modules/Index";
export const SetupModulesRoute = {
    path: "/manutencao/definir-modulos-alunos",
    component: SetupModulesPage,
    name: 'view-setup:student:modules',
    meta: {
     label: 'Atribuir módulos ao ciclo',
     iconClass: 'fa-chart-pie fas',
     category: 'maintenance',
    }
}

export default [
    StudentModuleOrderRoute,
    DeleteChallengesRoute,
    RepeatedUsersRoute,
    DeleteProcessPackagesRoute,
    SetupModulesRoute,
]