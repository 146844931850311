export default {
    state: {
        list: [],
        current: null,
        isLoadingProcessPackages: false,
        selection_list: []
    },
    actions: {
        getProcessPackages({commit, state}, params) {
          state.isLoadingProcessPackages = true
            return axios.get(`/admin/maintenance/process-package?${params}`)
            .then(r => {
                const  {data: { data: process_packages }} = r
                commit("SET_PROCESS_PACKAGES", process_packages);
                return r;
            })
            .finally(()=>state.isLoadingProcessPackages = false);
        },
    },
    mutations: {
        SET_PROCESS_PACKAGES(state, payload) {
            state.list = payload;
        },
        SET_PROCESS_PACKAGE_SELECTION_LIST(state, payload) {
            state.selection_list = payload;
        },
        CLEAR_PROCESS_PACKAGE_SELECTION_LIST(state) {
            state.selection_list = [];
            state.list = state.list.map(s=>{ s.selected = false; return s } )
        },
    },
    getters: {
        process_packages: state =>  state.list,
        isLoadingProcessPackages: state =>  state.isLoadingProcessPackages,
    }
  };
