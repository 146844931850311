<template>
  <el-row
    :gutter="4"
    class="px-4 mt-4"
  >
    <el-col :md="6">
      <el-input
        v-model="search"
        placeholder="Pesquisar"
        clearable
      />
    </el-col>
    <el-col :md="4">
        <el-select
          v-model="grade_id"
          placeholder="Série"
          clearable
          filterable
        >
          <el-option
            v-for="grade in grades"
            :key="grade.id"
            :label="grade.name"
            :value="grade.id"
          />
        </el-select>
      </el-col>
    <el-col :md="6">
      <el-select
        v-model="school_id"
        placeholder="Escola"
        clearable
        filterable
      >
        <el-option
          v-for="school in schools"
          :key="school.id"
          :label="school.name"
          :value="school.id"
        />
      </el-select>
    </el-col>
    <el-col :md="6">
      <el-radio-group v-model="status">
        <el-radio label="active">
          Ativos
        </el-radio>
        <el-radio label="inactive">
          Inativos
        </el-radio>
        <el-radio label="">
          Todos
        </el-radio>
      </el-radio-group>
    </el-col>
  </el-row>
</template>


<script>

import { mapActions, mapGetters } from 'vuex'

export default{
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          grade_id: '',
          status: 'active',
          school_id: '',
          meta : {
              current_page: 1,
              total: 10
          },
          showSelectionModal: false,
        }
    },
    computed:{
      ...mapGetters(['student','students','schools','grades']),
      filters(){
        return `&search=${this.search}&page=${this.meta.current_page}&school_id=${this.school_id}&status=${this.status}&grade_id=${this.grade_id}`
      }
    },
    watch: {
      filters(){
        clearTimeout(this._timerId)
        this._timerId = setTimeout(() => {
          this.$emit('input', this.filters)
        }, 500)
      },
    },
    mounted(){
      if(this.schools.length == 0 ){
        this.getSchools('&order_by=name&order_direction=asc');
      }
      if(this.grades.length == 0 ) {
        this.getGrades();
      }
    },
    methods:{
        ...mapActions(['getSchools','getGrades']),
    },
}
</script>
