<template>
  <div class="p-3">
    <el-row
      v-for="cycle in global_cycle_progress_list"
      :key="cycle.id"
      :gutter="10"
    >
      <el-col :span="3">
        <el-tooltip
          :content="`${cycle.begin_formatted} - ${cycle.end_formatted}`"
          placement="top"
          effect="light"
        >
          <span class="pointer-on-hover font-weight-bold">{{ cycle.cycle_name }}</span>
        </el-tooltip>
      </el-col>
      <el-col
        :span="16"
        class="pt-1"
      >
        <el-progress
          :text-inside="false"
          :show-text="false"
          :stroke-width="16"
          :percentage="getPercentage(cycle.avg_progress_until_cycle_end, greater_percentage)"
        />
      </el-col>
      <el-col :span="5">
        <p class="text-right">
          <el-tooltip
            content="Média de progresso até o fim do ciclo"
            placement="top"
            effect="light"
          >
            <span class="pointer-on-hover font-weight-bold">{{ cycle.avg_progress_until_cycle_end }}%</span>
          </el-tooltip> /
          <el-tooltip
            content="Alunos ativos"
            placement="top"
            effect="light"
          >
            <span class="pointer-on-hover font-weight-bold">{{ cycle.count_active_students }}</span>
          </el-tooltip>
        </p>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import getPercentage from '@/admin/utils/getPercentageGreater'

export default {
  computed: {
    ...mapGetters(['global_cycle_progress_list','is_loading_global_cycle_progress']),
    greater_percentage() {
      return _.orderBy(this.global_cycle_progress_list, ['avg_progress_until_cycle_end'], ['desc'])[0]['avg_progress_until_cycle_end'];
    },
  },
  methods: {
    getPercentage,
  },
}
</script>
