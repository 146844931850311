<template>
  <draggable
    v-model="themes"
    class="border"
  >
    <transition-group class="transition">
      <el-row
        v-for="(theme, index) in themes"
        :key="theme.id"
        style="cursor: grab"
        class="p-2"
      >
        <el-col>
          <el-alert
            :title="`Tema ${++index}: ${theme.name}`"
            :type="themes.length == modules_per_block ? 'success': themes.length > modules_per_block ? 'danger' : 'warning'"
            :closable="false"
          />
        </el-col>
      </el-row>
    </transition-group>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  data(){
    return {
      modules_per_block: 4,
    }
  },
  computed:{
    themes:{
      get(){
        return this.$store.state.theme_block.current.themes;
      },
      set(value){
        this.$store.state.theme_block.current.themes = value;
      }
    },
  }
}
</script>
