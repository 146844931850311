var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(`min-height: ${_vm.minHeight}`)},[(_vm.type === 'scorm' || _vm.type === 'video')?_c('iframe',{staticClass:"top-0 bottom-0 w-100 h-100 left-0 right-0 h-100 border-0",style:(`
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                min-height: ${_vm.minHeight};
              `),attrs:{"src":_vm.contentUrl}}):_vm._e(),_vm._v(" "),(_vm.type === 'link')?_c('ModuleLinkComponent',{attrs:{"link":_vm.content.link_content}}):_vm._e(),_vm._v(" "),(_vm.type === 'quiz')?_c('ModuleQuestionsComponent',{attrs:{"questions":_vm.content.quiz_content}}):_vm._e(),_vm._v(" "),(_vm.type === 'page')?_c('ModulePageComponent',{attrs:{"page":_vm.content.page}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }