const form = {
  type: 'pre_cycle',
  name: '',
  description:'',
  subject_id: '',
  trail_id: '',
  topic_id: '',
  thumbnail: '',
  contents: []
}
export default {
  state: {
    list: [],
    current: null,
    isLoadingPreCycleModules: false,
    form
  },
  actions: {
    getPreCycleModules({commit, state}, params = "") {
      state.isLoadingModules = true;
      return axios.get(`/admin/pre-cycle-module?${params}`)
      .then(r => {
        commit("SET_PRE_CYCLE_MODULES", r.data.data);
        return r;
      }).finally(() => state.isLoadingModules = false);
    },
    getPreCycleModule({commit}, {id}) {
      return axios.get(`/admin/pre-cycle-module/${id}`)
      .then(r => {
        commit("SET_PRE_CYCLE_MODULE", r.data.data);
        return r;
      });
    },
    createPreCycleModule({commit}, params) {
      return axios.post(`/admin/pre-cycle-module/`, params.data);
    },
    updatePreCycleModule({commit}, params) {
      return axios.put(`/admin/pre-cycle-module/${params.id}`, params.data);
    },
    deletePreCycleModule({commit}, {id}) {
      return axios.delete(`/admin/pre-cycle-module/${id}`);
    },
  },
  mutations: {
    SET_PRE_CYCLE_MODULES(state, payload) {
      state.list = payload;
    },
    SET_PRE_CYCLE_MODULE(state, payload) {
      state.current = payload;
      state.form = payload ? payload : form;
    }
  },
  getters:{
    pre_cycle_modules: state => state.list,
    pre_cycle_module: state => state.current,
    isLoadingPreCycleModules: state => state.isLoadingPreCycleModules,
  }
};
