<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    class="mb-2"
    label-position="top"
  >
    <el-row>
      <el-col>
        <el-form-item
          label="Nome"
          class="w-100"
          prop="title"
          :error="errors.title"
        >
          <el-input
            v-model="form.title"
            clearable
            name="title"
            show-word-limit
            maxlength="190"
            placeholder="Titulo"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Descrição"
          class="w-100"
          prop="description"
          :error="errors.description"
        >
          <el-input
            v-model="form.description"
            clearable
            name="description"
            show-word-limit
            maxlength="1000"
            placeholder="Descrição"
          />
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item
          label="Arquivo"
          class="w-100"
          prop="file"
          :error="errors.file"
        >
          <input
            id="file"
            ref="file"
            type="file"
            @change="onChangeFile"
          >
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-row class="mt-5 mb-2">
        <el-col>
          <el-button
            type="primary"
            @click="onSubmit"
          >
            Salvar
          </el-button>
          <el-button
            type="warning"
            @click="$emit('cancel')"
          >
            Cancelar
          </el-button>
        </el-col>
      </el-row>
    </el-row>
  </el-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import RequestErrors from "@/admin/utils/RequestErrors";
import rules from "./FormRules.js";

export default {
  mixins:[ RequestErrors ],
  data() {
    return {
      rules
    }
  },
  computed: {
    ...mapGetters(['file']),
    form: {
      get() {
        return this.$store.state.file.current;
      },
      set(file) {
        this.SET_FILE(file);
      }
    }
  },
  mounted() {
    if (this.file.id) {
      this.rules.file[0].required = false;
    }
  },
  methods: {
    ...mapMutations(['SET_FILE']),
    ...mapActions(['createFile','updateFile']),
    onChangeFile(e){
      this.form.file = e.target.value ? 'file' : ''
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$loading();
          const action = this.file.id ? this.updateFile({data: this.getData(), id: this.file.id}) : this.createFile({data: this.getData()});
          action
            .then(() => {
              this.$message({
                message: 'Página salva com sucesso.',
                type: 'success',
              });
              this.$emit('onSave')
            })
            .catch(e=>{
              this.showDefaultErrorMessages(e);
            })
            .finally(()=> this.$loading().close());
        } else {
          return false;
        }
      });
    },
    getData() {
      let formData = new FormData();
      formData.append('title', this.form.title)
      formData.append('description', this.form.description)
      formData.append('file', document.getElementById('file').files[0])
      this.file
      if(this.file.id)
        formData.append('_method','put');

      return formData;
    }
  },
}
</script>
