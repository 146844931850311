<template>
  <div class="d-flex justify-content-end p-3 el-button--primary">
    <el-dropdown @command="handleCommand">
      <div class="d-flex align-items-center">
        <span class="text-white mr-2">
          {{ authUser ? authUser.name : '' }}
        </span>
        <el-avatar 
          icon="el-icon-user-solid"
          :src="authUser && authUser.profile_photo_path ? authUser.profile_photo_path : '/assets/img/theme/team-4-800x800.jpg'"
        />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="logout">Sair</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters(['authUser']),
  },
    created(){
      this.getAuthUser()
      .catch(() => {
        this.$router.push('/admin/login')
      });
    },
  methods:{
    ...mapActions(['getAuthUser','logout']),
    handleCommand(command) {
        if(command == 'logout') {
          this.logout();
          this.$router.push('/admin/login')
        }
      }
  },
}
</script>