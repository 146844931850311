<template>
  <div class="mx--4">
    <el-skeleton
      :rows="10"
      :loading="isLoadingTrails && trails.length == 0"
      animated
    >
      <el-table
        :data="trails"
        style="width: 100%"
      >
        <el-table-column
          label="Id"
          prop="id"
          width="50"
        />
        <el-table-column
          label="Nome"
          prop="name"
        />
        <el-table-column
          fixed="right"
          label="Ações"
          width="220"
        >
          <template slot-scope="{ row }">
            <slot :row="row" />
          </template>
        </el-table-column>
      </el-table>
    </el-skeleton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import RequestErrors from "@/admin/utils/RequestErrors"
export default {
  mixins: [RequestErrors],
  computed:{
    ...mapGetters(['permissions','trails','isLoadingTrails']),
  },
  methods:{
    ...mapMutations(['SET_HISTORY', 'SET_TRAIL']),
    ...mapActions(['deleteTrail']),
  }
}
</script>

